import React from 'react'
import './Popups.css'


function Popups({ popupClass, popupsTitle, title, content, closeBtn, closeFunction }) {

    return (
        <div>
            <div className={popupClass}
            onClick={() => closeFunction()}>
                <div className={popupsTitle}
                onClick={() => closeFunction()}>
                    {title}
                <button className='popups-close-top' 
                onClick={() => closeFunction()}>
                    ×
                </button>
                </div> 
                    <div className='popups-text'>
                        <img src={content} 
                        alt={content}
                        onClick={() => closeFunction()}/>
                        <br />
                        <button className='popups-close'
                                onClick={() => closeFunction()}>
                                    {closeBtn}
                        </button>
                    </div>
            </div>
        </div>
    )
}

export default Popups