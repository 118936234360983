import { useEffect, useRef } from 'react'

function Line({quip}) {
	const cursorRef = useRef();

	useEffect(() => {
		const min = 1.5
		const max = 4

		const time = Math.random() * (max - min) + min;
		cursorRef.current.style.animation = `typing ${time}s steps(80, end) infinite, blinking .3s step-end infinite`;
	}, [])


return (
		<p className='load-text'>{quip}
			<span ref={cursorRef} className='cursor'></span>
		</p>
	);
}

export default Line