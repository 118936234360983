import React from 'react';
import './Shop.css';
import freakshop from './Img/merchanim.webp';

function Shop() {
  return (
    <div className='shop-container'>
      <div className='shop-body'>
        <span className='freak-text'>CLICK BELOW TO ENTER THE FREAKBAiT MERCH STORE</span>
        <br/>
        <div className='shop-image'>
          <a href='https://freakbait.myshopify.com/' target='_blank' rel='noreferrer' alt='FREAKBAiT MERCH STORE'>
            <img src={freakshop} alt='BUY MORE FREAKBAiT' style={{ maxWidth: '100%', height: 'auto' }}/>
          </a>
          <br/><br/>
        </div>
      </div>
    </div>
  );
}

export default Shop;
