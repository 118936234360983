import React, { useState } from 'react'
import Popups from './Popups'
import Delayed from './Delayed'
import Overlay from './Overlay'
import './Popups.css'
import tinypenis from './Img/tinypenis.gif'
import virus from './Img/virus.gif'
import stankass from './Img/stankass.gif'
import hotsingles from './Img/hotsingles.gif'
import jeffbezos from './Img/jeffbezos.gif'
import colonoscopy from './Img/colonoscopy.gif'
import curse from './Img/curse.gif'
import centipedes from './Img/centipedes.gif'
import visualworm from './Img/visualworm.gif'
import voidload from './Img/voidload.gif'
import implant from './Img/implant.gif'
import brainscan from './Img/brainscan.gif'
import neural from './Img/neural.gif'


function PopAttack() {

    const [pop1, setPop1] = useState(true)
    const Pop1Btn = () => {
        setPop2(!pop2)
        setPop1(!pop1)
      }
    const [pop2, setPop2] = useState(false)
    const Pop2Btn = () => {
        setPop2(!pop2)
        setPop3(!pop3)
      }
    const [pop3, setPop3] = useState(false)
    const Pop3Btn = () => {
        setPop3(!pop3)
        setPop4(!pop4)
      }
    const [pop4, setPop4] = useState(false)
    const Pop4Btn = () => {
        setPop4(!pop4)
        setPop5(!pop5)
      }
    const [pop5, setPop5] = useState(false)
    const Pop5Btn = () => {
        setPop5(!pop5)
        setPop6(!pop6)
      }
    const [pop6, setPop6] = useState(false)
    const Pop6Btn = () => {
        setPop6(!pop6)
        setPop7(!pop7)
      }
    const [pop7, setPop7] = useState(false)
    const Pop7Btn = () => {
        setPop7(!pop7)
        setPop1(!pop1)  //creates endless popup cycle
        setTrigger(true)
      }

      
    const [trigger, setTrigger] = useState(false)

    const [cursedPop, setCursedPop] = useState(false)
    const triggerBtn = () => {
        setPop1(false)
        setPop2(false)
        setPop3(false)
        setPop4(false)
        setPop5(false)
        setPop6(false)
        setPop7(false)
        setCursedPop(!cursedPop)
        setCursedPop2(!cursedPop2)
        setCursedPop3(!cursedPop3)
        setCursedPop4(!cursedPop4)
        setTrigger(!trigger)
      }
    
    const [cursedPop2, setCursedPop2] = useState(false)
    const cursedPop2Btn = () => {
      setCursedPop2(!cursedPop2)
    }
    const [cursedPop3, setCursedPop3] = useState(false)
    const cursedPop3Btn = () => {
      setCursedPop3(!cursedPop3)
    }
    const [cursedPop4, setCursedPop4] = useState(false)
    const cursedPop4Btn = () => {
      setCursedPop4(!cursedPop4)
    }
      
    const [overlay, setOverlay] = useState(false)
    const cursedPopBtn = () => {
        setTrigger(false)
        setCursedPop(false)
        setCursedPop2(false)
        setCursedPop3(false)
        setCursedPop4(false)
        setOverlay(true)
        setMainframe(true)
    }
      
    const [mainframe, setMainframe] = useState(false)


  return (
    <div>
    {pop1 ? <Popups
    popupClass="popups1"
    popupsTitle="popups-title"
    alt="GET A BIGGER WEINER"
    title="GET A BIGGER WEINER"
    content={tinypenis}
    closeBtn="BUY NOW"
    closeFunction={Pop1Btn}/> : null}

    {pop2 ? <Popups 
    popupClass="popups2"
    popupsTitle="popups-title2"
    alt="VIRUS VIRUS VIRUS"
    title="VIRUS VIRUS VIRUS"
    content={virus}
    closeBtn="DOWNLOAD .EXE"
    closeFunction={Pop2Btn}/> : null}

    {pop3 ? <Popups 
    popupClass="popups3"
    popupsTitle="popups-title3"
    alt="ALERT: STINKY ASS"
    title="ALERT: STINKY ASS"
    content={stankass}
    closeBtn="BUY SOAP"
    closeFunction={Pop3Btn}/> : null}

    {pop4 ? <Popups 
    popupClass="popups4"
    popupsTitle="popups-title4"
    alt="GET LAID NOW"
    title="GET LAID NOW"
    content={hotsingles}
    closeBtn="FIND MATCH"
    closeFunction={Pop4Btn}/> : null}

    {pop5 ? <Popups 
    popupClass="popups5"
    popupsTitle="popups-title5"
    alt="FREE BITCOIN"
    title="FREE BITCOIN"
    content={jeffbezos}
    closeBtn="ENTER CC INFO"
    closeFunction={Pop5Btn}/> : null}

    {pop6 ? <Popups 
    popupClass="popups6"
    popupsTitle="popups-title6"
    alt="ONLINE COLONOSCOPY"
    title="ONLINE COLONOSCOPY"
    content={colonoscopy}
    closeBtn="SIGN UP NOW"
    closeFunction={Pop6Btn}/> : null}

    {pop7 ? <Popups 
    popupClass="popups7"
    popupsTitle="popups-title7"
    alt="#"
    title="INFINITE CENTIPEDES"
    content={centipedes}
    closeBtn="CENTIPEDE ME"
    closeFunction={Pop7Btn}/> : null}
    
    {trigger ? 
    <Delayed waitBeforeShow={4000}> 
    <Popups 
    popupClass="cursed-pop"
    popupsTitle="cursed-pop-title "
    title="IT IS TOO LATE"
    content={curse}
    closeBtn="☣ͻ☀℧⛧֎⸸☠⚰"
    closeFunction={triggerBtn}/></Delayed> 
    : null}

    {cursedPop ?   
    <Delayed waitBeforeShow={800}> 
    <Popups 
    popupClass="visual-worm"
    popupsTitle="cursed-pop-title "
    title="VISUAL WORM IMPLANTED"
    content={visualworm}
    closeBtn="PROCEED"
    closeFunction={cursedPopBtn}/></Delayed>
    : null}
    
    {cursedPop2 ? 
    <Delayed waitBeforeShow={2200}> 
    <Popups 
    popupClass="visual-worm2"
    popupsTitle="cursed-pop-title "
    title="TRANSMITTING DATA"
    content={implant}
    closeBtn="ACCEPT"
    closeFunction={cursedPop2Btn}/></Delayed>
    : null}

    {cursedPop3 ?
    <Delayed waitBeforeShow={3400}> 
    <Popups 
    popupClass="visual-worm3"
    popupsTitle="cursed-pop-title "
    title="SCAN COMPLETE"
    content={brainscan}
    closeBtn="COMPLETE"
    closeFunction={cursedPop3Btn}/></Delayed>
    : null}

    {cursedPop4 ?
    <Delayed waitBeforeShow={3800}> 
    <Popups 
    popupClass="visual-worm4"
    popupsTitle="cursed-pop-title "
    title="NEURAL PROCESSING"
    content={neural}
    closeBtn="CLOSE"
    closeFunction={cursedPop4Btn}/></Delayed>
    : null}


    {overlay ?
    <>
    <Popups 
    popupClass="visual-worm"
    popupsTitle="cursed-pop-title "
    title="LOADING MAINFRAME"
    content={voidload}
    closeBtn="ENTER"
    closeFunction={null}/>
    </>
    : null}

    { mainframe ? 
    <>
    <Delayed waitBeforeShow={3500}>
      <Overlay 
        setMainframe={setMainframe}
        setOverlay={setOverlay}/>
    </Delayed>
    </> 
    : null}

</div>
    
)
}

export default PopAttack