import React, { useState } from 'react'
import YoutubeEmbed from '../../Components/YoutubeEmbed/YoutubeEmbed'
import "./Videos.css"

function Videos() {

  const [ todaysMission, setTodaysMisson ] = useState(false)
  const TMBtn = () => {
    setTodaysMisson(!todaysMission)
  }

  const [ freakbaitNews, setFreakbaitNews ] = useState(false)
  const FNBtn = () => {
    setFreakbaitNews(!freakbaitNews)
  }

  const [ comedy, setComedy ] = useState(false)
  const CMBtn = () => {
    setComedy(!comedy)
  }

  const [ buttcrackmirror, setButtcrackmirror ] = useState(false)
  const CCBtn = () => {
    setButtcrackmirror(!buttcrackmirror)
  }
  
  const [ freakbaitShorts, setFreakbaitShorts ] = useState(false)
  const FSBtn = () => {
    setFreakbaitShorts(!freakbaitShorts)
  }
  
  const [ brickMortar, setBrickMortar ] = useState(false)
  const BMBtn = () => {
    setBrickMortar(!brickMortar)
  }
  
  const [ mookielaka, setMookielaka ] = useState(false)
  const MLBtn = () => {
    setMookielaka(!mookielaka)
  }

  const [ spoogeNackin, setSpoogeNackin ] = useState(false)
  const SNBtn = () => {
    setSpoogeNackin(!spoogeNackin)
  }

  const [ nsfHiphop, setNsfHiphop ] = useState(false)
  const NSBtn = () => {
    setNsfHiphop(!nsfHiphop)
  }

  const [ freakbait, setFreakbait ] = useState(false)
  const FBBtn = () => {
    setFreakbait(!freakbait)
  }

  return (
    <>
      <div className='video-container'>
        <div className='video-titles'>MiCRoMEDiA</div><br/>
          <div className='video-sub' onClick={TMBtn}>TODAY'S MISSION <div className='down-arrow'>▼</div></div>
          {todaysMission ?  <div className='video-description'>
          <div className='video-text'>
              With collectively over 250 million views - and imitated worldwide - the most popular FREAKBAiT series provides seeds of Little Chaos to sow throughout the world. Part comedy series, part ARG, and in whole an experiment in human psychology - TODAY'S MISSION gives your day to day life a little spice.
              <br/><br/><a href="https://www.tiktok.com/t/ZTR5Yjvuw/">TODAY'S MISSION on TikTok</a>
              <br/><a href="https://youtube.com/playlist?list=PLNOhvqcJZLWjXdfJK4wCfZVzxq0VyCpoh&si=tpU-Xau_TzyIQ5Pg">TODAY'S MISSION on YouTube</a>
              <div className='video-grid2'>
              <YoutubeEmbed embedId='dq0fgckbPiE'/>
              </div>

            </div></div>: null}
            <br/>
            <div className='video-sub'onClick={FNBtn}>FREAKBAiT NEWS<div className='down-arrow'>▼</div></div>
           {freakbaitNews ? <div className='video-description'>
          <div className='video-text'>
              A series of bite-sized vignettes exploring interesting, disturbing, funny, and tragic characters and events throughout history. YOU'RE WATCHING FREAKBAiT NEWS!
              <br/><br/><a href="https://www.tiktok.com/t/ZTR521yJc/">FREAKBAiT NEWS on TikTok</a>
              <br/><a href="https://www.youtube.com/watch?v=-2CWkn89waI&list=PLNOhvqcJZLWg3mRtKIn9hGJxYi_OxAYQS">FREAKBAiT NEWS on YouTube</a>
              <div className='video-grid2'>
              <YoutubeEmbed embedId='MU9NV0RUI9A'/>
              </div>
            </div>

            </div> : null}
            <br/>
            <div className='video-sub'onClick={CMBtn}>CoMEDY<div className='down-arrow'>▼</div></div>
            {comedy ?  <div className='video-description'>
          <div className='video-text'>
            Surrealist shorts, satirical sketches, and absurdity abound.  Not only entertaining, but oftentimes also informative - including interludes on neuroscience, witticisms about language, caustic social commentary, and lots of fart jokes. Like, a lot.
            <br/><br/><a href="https://www.tiktok.com/t/ZTR5YQW1M/">CoMEDY PLAYLIST on TikTok</a>
              <br/><a href="https://www.youtube.com/watch?v=UlNKTslMgHk&list=PLNOhvqcJZLWjic4wCWKVpOjqAt8XfMaXM">CoMEDY PLAYLIST on YouTube</a>

          </div>
                <div className='video-grid2'>
                <YoutubeEmbed embedId='ZrdQRES7as4'/>
              </div></div> : null}
            <br/>
            <div className='video-sub' onClick={CCBtn}>BuTTCRaCK MiRRoR<div className='down-arrow'>▼</div></div>
              {buttcrackmirror ?    <div className='video-description'>
          <div className='video-text'>
              Speculative scifi horror through the eyes of FREAKBAiT.  Social commentary with tongue firmly placed in cheek and elbow digging in your ribs, this series of heavy-handed metaphors for modern culture gone awry is aptly named after the hit Netflix show.
              <br/><br/><a href="https://www.tiktok.com/t/ZTR5YQW1M/">BUTTCRACK MiRROR on TikTok</a>
              <br/><a href="https://www.youtube.com/watch?v=LCOerGaWCcc&list=PLNOhvqcJZLWik324N61IGhOlNvSMsYKf2">BUTTCRACK MiRROR on YouTube</a>
          </div>
                <div className='video-grid2'>
                <YoutubeEmbed embedId='9Kd0peWJSfo'/>
                </div></div>  : null}
            <br/>
        <div className='video-titles'>SHoRTS</div><br/>
          <div className='video-sub' onClick={FSBtn}>FREAKBAiT SHoRTS<div className='down-arrow'>▼</div></div>
           {freakbaitShorts ?  <div className='video-description'>
          <div className='video-text'>
              Longer form content exploring madman dreams, comic horror, and unclassifiable experimentation.
          </div>
          
            <div className='video-grid2'>
                <YoutubeEmbed embedId='OaNm5gTVl1g'/>
                <YoutubeEmbed embedId='AprgI2buOPw'/>
            </div></div>  : null}
            <br/>
        <div className='video-titles'>MUSiC ViDEOS</div><br/>
        <div className='video-sub' onClick={BMBtn}>BRiCK + MORTAR<div className='down-arrow'>▼</div></div>
            {brickMortar ?  <div className='video-grid2'>
              <YoutubeEmbed embedId='tUVIq8FoLDU'/>
            </div>  : null} <br/>
          <div className='video-sub' onClick={MLBtn}>MOOKiELAKA<div className='down-arrow'>▼</div></div>
            {mookielaka ?  <div className='video-grid2'>
              <YoutubeEmbed embedId='QPuy05PsqUc'/>
              <YoutubeEmbed embedId='G4kzkS0uKKQ'/>
              <YoutubeEmbed embedId='KLObkjcaOFc'/>
              <YoutubeEmbed embedId='GMBU3_M5IbU'/>
            </div>  : null} <br/>
          <div className='video-sub' onClick={SNBtn}>SPooGE NACKiN<div className='down-arrow'>▼</div></div>
            {spoogeNackin ?  <div className='video-grid2'>
              <YoutubeEmbed embedId='qYg94_BX5b0'/>
            </div>  : null} <br/>
          <div className='video-sub' onClick={FBBtn}>FREAKBAiT<div className='down-arrow'>▼</div></div>
            {freakbait ? <div className='video-grid2'>
              <YoutubeEmbed embedId='w0XIKEhQ5Sk'/>
            </div> : null}<br/>
          <div className='video-sub' onClick={NSBtn}>NSF<div className='down-arrow'>▼</div></div>
          {nsfHiphop ?  <div className='video-grid2'>
              <YoutubeEmbed embedId='rV0uU0WqmYc'/>
              <YoutubeEmbed embedId='c81xu4n5c4k'/>
              <YoutubeEmbed embedId='Osli87n34lw'/>
              <YoutubeEmbed embedId='HGbO8UHb7rs'/>
              <YoutubeEmbed embedId='R30qrm5j_Tg'/>
              <YoutubeEmbed embedId='_vL0NjS6OgQ'/>
              <YoutubeEmbed embedId='k1Z0PEMFiGI'/>
            </div> : null}
            <br/>
      </div>
    </>
  )
}

export default Videos
