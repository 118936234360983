import React from 'react'

function FreakFiles() {

  const MkList = ['Subproject 1:  Plants Isolation and Characterization of Rivea Corymbosa',
    'Subproject 2:  Drugs',
    'Subproject 3:  Testing',
    'Subproject 4:  Mulholland’s Manual',
    'Subproject 5a: MKULTRA',
    'Subproject 5b:  Denver University Hypnosis',
    'Subproject 6:  Testing of Plants by HEF',
    'Subproject 7:  Funding; ONR Probably Abramson',
    'Subproject 8:  Boston Psychopathic Hospital',
    'Subproject 9:  Depressants, Schizophrenics, Alcoholics',
    'Subproject 10:  Personality Assessment',
    'Subproject 11:  Botanicals Popkin (Documents and articles on Luis Angel Castillo)',
    'Subproject 12:  Financial records',
    'Subproject 13:  CIA Support to Fort Detrick',
    'Subproject 14:  Paying Bureau of Narcotics for White',
    'Subproject 15:  Magic Support; Mulholland Supplement',
    'Subproject 16:  Testing Apartment Rental',
    'Subproject 17:  LSD Studies of [EXCISED] University',
    'Subproject 19:  Magic Manual',
    'Subproject 20:  Synthesis Derivative of Yohimbine Hydrochloride',
    'Subproject 21:  Defector Study: originally Drug Study',
    'Subproject 22:  William Cook and Co. Research: Amanita Muscaria, Rivea Corymbosa',
    'Subproject 26:  Pfeiffer, Finances',
    'Subproject 27:  ONR Funding, LSD Research',
    'Subproject 28:  Pfeiffer',
    'Subproject 30:  Fort Detrick (1)',
    'Subproject 30:  Fort Detrick (2)',
    'Subproject 30:  Fort Detrick (3)',
    'Subproject 31:  Manufacture of Drugs by Pellow Wease Chemical Co.',
    'Subproject 32:  Collection of Plants',
    'Subproject 33:  Collection of 400 for SUBPR #27',
    'Subproject 34:  More Support to Magic',
    'Subproject 35:  Georgetown Hospital: Geschichter',
    'Subproject 36:  Cuba Chapter Conference, Consultant, Subproject involving getting a man on a diverted freighter',
    'Subproject 37:  Collection of Botanicals',
    'Subproject 38: MKULTRA',
    'Subproject 39:  Iowa State Hospital (and Ionia)',
    'Subproject 40:  Funding, Probably Abrams LSD Research',
    'Subproject 41:  FREAKBAiT',
    'Subproject 42:  Safehouse Chapter 7: MKULTRA Interview Notes White, George Hunter Dope Traffickers’ Nemesis',
    'Subproject 43:  Combination drug, Hypnosis, Sensory Deprivation',
    'Subproject 44:  Testing of Aromatic Amines at University of Illinois',
    'Subproject 45:  Knockout, Stress, Cancer',
    'Subproject 46:  Rochester LSD Drugs',
    'Subproject 47:  Pfeiffer Atlanta',
    'Subproject 47:  (1) Pfeiffer Atlanta/Bordertown',
    'Subproject 48:  HEF Cornell Relationship: Artichoke Team Proposals and Reports',
    'Subproject 49:  Hypnosis at [EXCISED] University',
    'Subproject 50:  CIA Imprest Fund for $500',
    'Subproject 51:  (1) Moore Collecting Botanicals',
    'Subproject 52:  (2) Moore Collecting Botanicals',
    'Subproject 53:  (3) Moore Collecting Botanicals',
    'Subproject 53:  Review Pharmacological lit.',
    'Subproject 54:  Brain Concussion',
    'Subproject 55:  Unwitting Drug Tests at [EXCISED] University',
    'Subproject 56:  Studies on Alcohol, Stanford Medical school',
    'Subproject 57:  Sleep and Insomnia at GW:  Lloyd Gould',
    'Subproject 57:  Sleep',
    'Subproject 58:  J. P. Morgan and Co. (see Wasson file) Agency Policy and Conferences',
    'Subproject 59:  Unwitting Drug Tests at University of Maryland',
    'Subproject 60:  Human Ecology',
    'Subproject 61: MKULTRA',
    'Subproject 62:  Consulting Work in Isolation/Electric Shock/CNS Drugs',
    'Subproject 63:  (1) Drugs and Alcohol (Butler)',
    'Subproject 64:  Drugs',
    'Subproject 65:  Hungarian Refugees',
    'Subproject 66:  Alcohol and Drug Study',
    'Subproject 67:  CIA Use of Institutes Facilities – University of Indiana',
    'Subproject 69:  Rutgers',
    'Subproject 70:  “Knockout”',
    'Subproject 71:  Dr. Wallace Chan at Stanford University Testing Drugs',
    'Subproject 72:  Testing Drugs for Effects on Central Nervous System',
    'Subproject 73:  University of Kentucky: Narcotics Farms, Narco-Hypnosis',
    'Subproject 74:  Small HEF Subproject (1)',
    'Subproject 74:  Small HEF Subproject (2)',
    'Subproject 75:  Mass. Mental Health (by Project number of master list)',
    'Subproject 77:  Biological Lab (1)',
    'Subproject 78:  Biological Lab (2)',
    'Subproject 78:  Biological Lab (3)',
    'Subproject 78:  Biological Lab (4)',
    'Subproject 78:  Biological Lab (5)',
    'Subproject 78:  Biological Lab (6)',
    'Subproject 79:  Cutout for Funding Research of a “sensible nature”',
    'Subproject 80:  MKULTRA',
    'Subproject 81:  Cornell–Extension of Hinkle–Wolf',
    'Subproject 82:  Hungarian Refugees',
    'Subproject 83:  Graphology Journal and Cover',
    'Subproject 84:  Hypnosis Work',
    'Subproject 85:  Stanford Medical School',
    'Subproject 86:  Stanford Medical School: Telecontrol',
    'Subproject 87:  Hyper-Allergic Substances',
    'Subproject 88:  Cultural Appraisal',
    'Subproject 89:  Hungarian Repatriation',
    'Subproject 90:  MIT – A. J. Wiener',
    'Subproject 91:  Drug Testing and Screening of Animals',
    'Subproject 92:  Teaching Machine for Foreign Languages',
    'Subproject 93:  Toxin Study – Cuba Chapter',
    'Subproject 94: MKULTRA',
    'Subproject 95:  Osgood',
    'Subproject 96:  George Kelly',
    'Subproject 97:  Schizophrenics Psychotherapy',
    'Subproject 98:  Mass Conversion Study: Queens College',
    'Subproject 99:  Optics Mixed with Biological Warfare – Cuba Chapter',
    'Subproject 100:  CBW Penn State',
    'Subproject 101:  Biophysics of Central Nervous System',
    'Subproject 102:  Adolescent Gangs',
    'Subproject 103:  Children’s Summer Camps',
    'Subproject 104:  Sabotage of Petroleum',
    'Subproject 105:  CBW, Disease',
    'Subproject 106:  Electrodes, Russian Study',
    'Subproject 109:  Drugs-CBW Testing',
    'Subproject 110:  CBW MKNAOMI',
    'Subproject 112:  Vocational Studies in Children',
    'Subproject 113:  Gas Sprays and Aerosols',
    'Subproject 114:  Alcohol Study',
    'Subproject 115:  Mentally Disturbed and Environment',
    'Subproject 116:  Lab',
    'Subproject 117:  Cultural Influences on Children',
    'Subproject 118:  Microbiology – Penn State',
    'Subproject 119:  Telecontrol – Texas Christian',
    'Subproject 120:  MKULTRA Drug Research',
    'Subproject 121:  Witch Doctor Study - Dr. Raymond Prince – McGill University',
    'Subproject 122:  Study of Neurokinin',
    'Subproject 123:  African Attitude Study',
    'Subproject 124:  African Attitude Study',
    'Subproject 125:  CO2 and Acid Base Research',
    'Subproject 126:  Work on Placebos and Drugs',
    'Subproject 127:  Disaster/Stress Study',
    'Subproject 128:  Rapid Hypnotic Induction',
    'Subproject 130:  Personality Theory, David Saunders/William Thetford; Columbia Univ.',
    'Subproject 131: MKULTRA',
    'Subproject 132:  Safe House – Not San Francisco',
    'Subproject 133:  Safe House – Not San Francisco',
    'Subproject 134:  Correlation Of Physique and Personality done by Haronian in New Jersey – Human Ecology',
    'Subproject 135:  Testing on Volunteers',
    'Subproject 136:  ESP Research',
    'Subproject 137:  Handwriting Analysis, Dr. Klare G Toman-HEF',
    'Subproject 139:  Bird Disease Studies at Penn State',
    'Subproject 140:  human Voluntary Drug Testing',
    'Subproject 141:  Unknown',
    'Subproject 142:  Unknown',
    'Subproject 143:  MKULTRA CBW/Bacteria University of Houston',
    'Subproject 144:  MKULTRA',
    'Subproject 145:  MKULTRA',
    'Subproject 146:  MKULTRA',
    'Subproject 147:  Psychometric Drugs THC',
    'Subproject 148:  (1) Marijuana Research',
    'Subproject 148:  (2) Marijuana Research',
    'Subproject 149:  George White and Federal Bureau of Narcotics',]
    
    const items = MkList.map((title) => {
        return <>
        <li key={title}>{title}</li>
        <br/></>
    });

  return (
  <div className='freakfile-container'>
    <dv className='freak-title'>
      MKULTRA SUBPROJECTS LIST
    </dv>
      <div className='freak-list'>
        <ul>{items}</ul>
      </div>
  </div>
  )
}

export default FreakFiles
