import { useState } from 'react'
import './ExhibitCabinet.css'
import cableStandTest from "../../Pages/FreakFiles/Img/CableStandTest.png"
import darkAlliance1 from "../../Pages/FreakFiles/Img/DarkAlliance1.png"
import deletePhysicalViolence from "../../Pages/FreakFiles/Img/DeletePhysicalViolence.png"
import DEWBallLightning from "../../Pages/FreakFiles/Img/DEWBallLightning.png"
import gitMo from "../../Pages/FreakFiles/Img/GitMo.png"
import hypnoSex from "../../Pages/FreakFiles/Img/HypnoSex.jpg"
import kerryReport from "../../Pages/FreakFiles/Img/KerryReport.png"
import manualOnTrickery from "../../Pages/FreakFiles/Img/ManualonTrickery.jpg"
import projectSilverBug from "../../Pages/FreakFiles/Img/ProjectSilverBug.png"
import psyop from "../../Pages/FreakFiles/Img/PSYOP.png"
import remoteViewing from "../../Pages/FreakFiles/Img/RemoteViewing.png"
import skyFire from "../../Pages/FreakFiles/Img/SkyFire.png"
import smoothModelInversion from "../../Pages/FreakFiles/Img/SmoothModelInversion.png"
import studyOfAssassination from "../../Pages/FreakFiles/Img/StudyOfAssassination.png"
import weatherModFog from "../../Pages/FreakFiles/Img/WeatherModFog.png"



function ExhibitCabinet() {

  const picList = [
    {
        id: 'cablestandtest',
        image: cableStandTest,
    },
    {
        id: 'darkalliance',
        image: darkAlliance1,
    },
    {
        id: 'deletePhysicalViolence',
        image: deletePhysicalViolence,
    },
    {
        id: 'DEWBallLightning',
        image: DEWBallLightning,
    },
    {
        id: 'gitmo',
        image: gitMo,
    },
    {
        id: 'hypnoSex',
        image: hypnoSex,
    },
    {
        id: 'kerryReport',
        image: kerryReport,
    },
    {
        id: 'manualOnTrickery',
        image: manualOnTrickery,
    },
    {
        id: 'projectSilverBug',
        image: projectSilverBug,
    },
    {
        id: 'psyop',
        image: psyop,
    },
    {
        id: 'remoteViewing',
        image: remoteViewing,
    },
    {
        id: 'skyFire',
        image: skyFire,
    },
    {
        id: 'smoothModelInversion',
        image: smoothModelInversion,
    },
    {
        id: 'studyOfAssassination',
        image: studyOfAssassination,
    },
    {
        id: 'weatherModFog',
        image: weatherModFog,
    },
]

const [open, setOpen] = useState(null);

const [showImage, setShowImage] = useState({
    showImageNow: true,
    showImageId: null,
})

const {showImageNow, showImageId} = showImage
    
const OpenImage = (a) => {
    setOpen(a.image);
    setShowImage({
        showImageNow: !showImageNow,
        showImageId: a.id
    })
}

return(
    <div>

      {picList.map((a, i) => 
        <div key={i}>

          <button
          className='exhibit-button' 
          onClick={() => OpenImage(a)}
          >
            {a.id}
          </button>

          <br />

          {showImageNow && showImageId === a.id ?
            <img
              src={open} alt={a.id} 
            />
          :null}
        </div>
      )}
    </div>
  )
}

export default ExhibitCabinet