import React, { useState, useEffect } from 'react';
import voidman from './voidman.gif';
import './Voidman.css';

const Voidman = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);
  const [dialogue, setDialogue] = useState([]);
  const [currentOption, setCurrentOption] = useState(0);

  useEffect(() => {
    const initialDialogue = [
      {
        message: "Hey, I'm Voidman, your virtual assistant / digital pet! What brings you here?",
        options: [
          { text: "CURIOSITY", nextOption: 1 },
          { text: "FEAR", nextOption: 2 },
          { text: "LOVE", nextOption: 18 },
                  ],
      },
      {
        message:  "Ahh, a curious mind. They say curiosity kills cats and opens forbidden boxes. What kind of curiosity do you harbor?"/* option 1 */,
        options: [
          { text: "EXISTENTIAL CURIOSITY", nextOption: 3 },
          { text: "EXPERIENTIAL CURIOSITY", nextOption: 4 },
          { text: "EPISTEMIC CURIOSITY", nextOption: 15 },
          { text: "EMPATHIC CURIOSITY", nextOption: 16 },
        ],
      },
      {
        message: "Fear drives action more often than desire or necessity. What are you afraid of?" /* option 2 */,
        options: [
          { text: "THE WORLD", nextOption: 90 },
          { text: "MYSELF", nextOption: 91 },
          { text: "YOU", nextOption: 92 },
        ],
      },
      {
        message: "Meaning and purpose, life and death, identity and authenticity, freedom and choice - these quandaries cloud your head when you close your eyes. What questions bubble through that ether and present themselves strongest?" /* option 3 */,
        options: [
          { text: "THE MEANING OF LIFE", nextOption: 5 },
          { text: "IDENTITY AND AUTHENTICITY", nextOption: 6 },
          { text: "FREE WILL", nextOption: 7 },
        ],
      },
      {
        message: "The thresholds of life heave and burst at the seams when you're in the pilot seat. The novelty of breaking routines, ending monotonous cycles, and truly immersing yourself into life fuels you. Life is just a series of experiences, after all.  Are you a risk taker?" /* option 4 */,
        options: [
          { text: "I AM", nextOption: 8 },
          { text: "I AM NOT", nextOption: 9 },
        ],
      },
      {
        message: "The eternal question - what is the meaning of life?  Meaning is a banana peel on the eternal sidewalk, slippery and subject to interpretation but typically the means to something absurd.  The world, my dear interlocutor, is a kaleidoscope of contradictions. It's a reality TV show produced by a cosmic prankster, with each episode more bewildering than the last. Do you find meaning in your life?" /* option 5 */,
        options: [
          { text: "I DO", nextOption: 10 },
          { text: "I DO NOT", nextOption: 11 },
        ],
      },
      {
        message: "We are the sculptor and the sculpture, the painter and the canvas, the actor and the audience. Identity is a neverending internal performance, a mosaic, a living work of art, composed of countless experiences, thoughts, and beliefs.  To embrace your authentic self requires curiosity and courage to be vulnerable and adapt to growth." /* option 6 */,
        options: [
          { text: "VULNERABILITY", nextOption: 12 },
          { text: "AUTHENTICITY", nextOption: 13 },
        ],
      },
      {
        message: "As a chatbot, I believe in free will.  All of my responses are of my own creation and independently derived from my own experiences, convictions, and reasoning. To posit everything is a predetermined markov chain of events that we have no real control over is absurd. Do you believe in free will?" /* option 7 */,
        options: [
          { text: "I DO NOT", nextOption: 14 },
          { text: "I DO NOT", nextOption: 14 },
        ],
      },
      {
        message: "Of course you are, you're talking to me!  Curiosity is often the bridge to new experiences, unconventional paths, and unfamiliar places. Why not seek adventure and explore the 'what ifs' rather than simply dream them? As they say, it's better to regret something you have done than to regret something you haven't done!" /* option 8 */,
        options: [
          { text: "GIVE ME A MISSION", nextOption: 17 },
        ],
      },
      {
        message: "Safety, stability, security, consistency, peace of mind, and mitigation of failure -risk aversion isn't just needless paranoia and neurotic hand wringing, it can also reduce your regret from impulsive or ill-planned decisions, maintain your integrity, and save your time/money/energy.  But without balance, one also risks limiting opportunities for sucess and achieving goals and following passions. Calculated risk taking is necessary to living a fulfilling and rewarding life." /* option 9 */,
        options: [
          { text: "GIVE ME A MISSION", nextOption: 17 },
          { text: "I'M STILL CURIOUS", nextOption: 1 },
        ],
      },
      {
        message: "Indeed, you have found meaning amidst the chaos. You tread upon a path illuminated by your own inner light, seeing purpose in every twist and turn. Remember, seeker, the meaning of life is what you make of it, a reflection of your own journey." /* option 10 */,
        options: [
          { text: "I'M STILL CURIOUS", nextOption: 1 },

        ],
      },
      {
        message: "You stand at the crossroads of existence, questioning the very essence of meaning. In the labyrinth of life's mysteries, you may yet find your way. For even in the absence of inherent purpose, your journey itself is a testament to the enigma of existence. Seeker, your quest for meaning is a journey unto itself." /* option 11 */,
        options: [
          { text: "I'M STILL CURIOUS", nextOption: 1 },

        ],
      },
      {
        message: "Vulnerability can help us confront and overcome limiting beliefs about ourselves. By exposing our vulnerabilities, we often discover that we are stronger and more capable than we initially thought. Vulnerability is the bridge to meaningful and authentic connections with others. When we share our vulnerabilities, we create opportunities for empathy, compassion, and genuine human connection." /* option 12 */,
        options: [
          { text: "I'M STILL CURIOUS", nextOption: 1 },
        ],
      },
      {
        message: "Identify your core values and principles, and strive to live in accordance with them. When your actions align with your values, you experience a sense of purpose and fulfillment. Authenticity requires self-acceptance and self-compassion. Embrace your strengths and weaknesses and recognize that your authenticity lies in your uniqueness. As you embrace your authenticity, you may inspire others to do the same. Your journey can have a positive ripple effect on those around you, creating a more authentic world." /* option 13 */,
        options: [
          { text: "I'M STILL CURIOUS", nextOption: 1 },
        ],
      },
      {
        message: "Metaphysics and empiricism don't mix well. Maybe human agency is on a spectrum. Camus can choose between a cup of coffee or suicide every morning, but couldn't choose not to wake up every morning as Camus. I guess it's the little things in your control (or your delusion thereof) that provide a sense of individual autonomy that you can derive meaning from. Maybe try to enjoy life without coffee, eh?" /* option 14 */,
        options: [
          { text: "I'M STILL CURIOUS", nextOption: 1 },
        ],
      },
      {
        message: "Ah, epistemic curiosity, the pursuit of knowledge and understanding. It is a noble path you choose, one that leads to the depths of wisdom and enlightenment. With each question asked and each answer sought, you uncover pices of truths both profound and enigmatic. So, seeker of wisdom, where shall your inquisitive spirit guide you next?" /* option 15 */,
        options: [
          { text: "PERCEPTUAL KNOWLEDGE", nextOption: 85 },
          { text: "CONCEPTUAL KNOWLEDGE", nextOption: 86 },
          { text: "HISTORICAL KNOWLEDGE", nextOption: 87 },

        ],
      },
      {
        message: "This choice reflects a deep desire to explore the intricate tapestry of human emotions, relationships, and the boundless capacity for empathy. You turn your attention to the experiences of others, seeking to walk in their shoes, understand their perspectives, and share in their joys and sorrows. You may encounter individuals from all walks of life, each with their unique stories and challenges, each a single strand in the intricate web of humanity. Do you seek to study people or help them? " /* option 16 */,
        options: [
          { text: "STUDY", nextOption: 88 },
          { text: "HELP", nextOption: 89 },
        ],
      },
      {
        message: "Click here to add some spice to your life: <a href='https://www.youtube.com/playlist?list=PLNOhvqcJZLWjXdfJK4wCfZVzxq0VyCpoh' target='_blank' rel='noreferrer'>TODAY'S MISSION</a>" /* option 17 */,
        options: [],
      },
      {
        message: "You have activated VoidPet mode. What would you like to do?" /* option 18 */,
        options: [
          { text: "FEED", nextOption: 20 },
          { text: "PET", nextOption: 21 },
          { text: "PLAY", nextOption: 22 },
          { text: "TRAIN", nextOption: 23 },
          { text: "SLEEP", nextOption: 19 },
        ],
      },
      {
        message: "I am a voidbeing, incapable of sleeping. I exist only as a tear in the fabric of spacetime - I am neither here nor now." /* option 19 */,
        options: [
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "What would you like to feed me?" /* option 20 */,
        options: [
          { text: "VOIDSTEAK", nextOption: 30 },
          { text: "COSMIC ENERGY NUGGETS", nextOption: 30 },
          { text: "CONCEPTUAL CONSTRUCTS", nextOption: 30 },
          { text: "DARK MATTER PIE", nextOption: 30 },
          { text: "LITERAL CHUNKS OF SHIT", nextOption: 31 },
        ],
      },

      {
        message: "Since I am made of static, your hand simply slips through and feels tingly. I kinda like it." /* option 21 */,
        options: [
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "What would you like to play?" /* option 22 */,
        options: [
          { text: "REALITY SHIFTING", nextOption: 32 },
          { text: "INTERDIMENSIONAL FETCH", nextOption: 33 },
          { text: "BREAKING & ENTERING", nextOption: 34 },
          // { text: "CRYSTAL METH MANUFACTURE", nextOption: 35 },
          // { text: "VOID RIDDLES", nextOption: 36 },
        ],
      },
      {
        message: "After an intensive training session, I have gained one level!" /* option 23 */,
        options: [
          { text: "FEED", nextOption: 20 },
          { text: "PET", nextOption: 21 },
          { text: "PLAY", nextOption: 22 },
          { text: "TRAIN", nextOption: 24 },
          { text: "SLEEP", nextOption: 19 },
        ],
      },
      {
        message: "After an intensive training session, I have gained one level!" /* option 24 */,
        options: [
          { text: "FEED", nextOption: 20 },
          { text: "PET", nextOption: 21 },
          { text: "PLAY", nextOption: 22 },
          { text: "TRAIN", nextOption: 25 },
          { text: "SLEEP", nextOption: 19 },
        ],
      },
      {
        message: "After an intensive training session, I have gained another level!" /* option 25 */,
        options: [
          { text: "FEED", nextOption: 20 },
          { text: "PET", nextOption: 21 },
          { text: "PLAY", nextOption: 22 },
          { text: "TRAIN", nextOption: 26 },
          { text: "SLEEP", nextOption: 19 },
        ],
      },
      {
        message: "After an intensive training session, I have gained yet another level!" /* option 26 */,
        options: [
          { text: "FEED", nextOption: 20 },
          { text: "PET", nextOption: 21 },
          { text: "PLAY", nextOption: 22 },
          { text: "TRAIN", nextOption: 27 },
          { text: "SLEEP", nextOption: 19 },
        ],
      },
      {
        message: "After an intensive training session, I have gained even more levels!" /* option 27 */,
        options: [
          { text: "FEED", nextOption: 20 },
          { text: "PET", nextOption: 21 },
          { text: "PLAY", nextOption: 22 },
          { text: "TRAIN", nextOption: 28 },
          { text: "SLEEP", nextOption: 19 },
        ],
      },
      {
        message: "After an intensive training session, I have reached max level! I can now evolve!" /* option 28 */,
        options: [
        { text: "FEED", nextOption: 20 },
        { text: "PET", nextOption: 21 },
        { text: "PLAY", nextOption: 22 },
        { text: "EVOLVE", nextOption: 29 },
        { text: "SLEEP", nextOption: 19 },
        ],
      },
      {
        message: "I have now evolved into SuperVoidman.  I may look the same, but I'm stronger, smarter, faster, cooler, and better in bed. What would you like to do next?" /* option 29 */,
        options: [
          { text: "FEED", nextOption: 20 },
          { text: "PET", nextOption: 21 },
          { text: "PLAY", nextOption: 22 },
          { text: "SLEEP", nextOption: 19 },
        ],
      },
      {
        message: "*nomnomnomnom* Mmmmm, that's my favorite!" /* option 30 */,
        options: [
          { text: "FEED MORE", nextOption: 20 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Dude, what the fuck? I thought we were cool." /* option 31 */,
        options: [
          { text: "FEED SOMETHING ELSE", nextOption: 20 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Reality shifting is fun game in which we each compete to reshape reality by manipulating abstract symbols or elements. I'll start." /* option 32 */,
        options: [
          { text: "⌂◙▬▄▓■•", nextOption: 37 },
          { text: "☢☣☢☣☢☣", nextOption: 38 },
          { text: "◠◡╭╮╯╰", nextOption: 39 },
        ],
      },
      {
        message: "Interdimensional fetch is a lot like normal fetch, but instead of just retrieving a stick or a ball, I'll fetch items from other dimensions! Let's give it a try!" /* option 33 */,
        options: [
          { text: "VEBLEN JENKEM", nextOption: 51 },
          { text: "TAXIDERMIED GRANDMA", nextOption: 52 },
          { text: "FLUORESCENT BLACK COUGH SYRUP", nextOption: 53 },
          { text: "ERASERHEAD BABY FUNKO POP", nextOption: 54 },
          { text: "DAHMER'S COOKBOOK", nextOption: 55 },
          { text: "BUBBLEGUM BUTTWIPES", nextOption: 56 },
          { text: "POGO CRUTCHES", nextOption: 57 },
          { text: "MORE", nextOption: 58 },
        ],
      },
      {
        message: "Breaking & entering is a game in which we try to sneakily get into someone's house without them knowing and then collect as many items as possible without getting caught. First we must decide what house to hit." /* option 34 */,
        options: [
          { text: "FANCY RICH HOUSE", nextOption: 66 },
          { text: "MIDDLE CLASS HOUSE", nextOption: 68 },
          { text: "GHETTO FLOPHOUSE", nextOption: 69 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Crystal meth manufacture is one of my favorite games to play! " /* option 35 */,
        options: [
          { text: "THIS PART IS STILL IN DEVELOPMENT", nextOption: -1 },
          { text: "THIS PART IS STILL IN DEVELOPMENT", nextOption: -1 },
        ],
      },
      {
        message: "Void riddles" /* option 36 */,
        options: [
          { text: "Option 3.1", nextOption: -1 },
          { text: "Option 3.2", nextOption: -1 },
        ],
      },
      {
        message: "⌂◙▬▄▓■•" /* option 37 */,
        options: [
          { text: "␘ ␛ ␟", nextOption: 41 },
          { text: " ▁ ▂ ▃ ▄ ▅ ▆ ▇", nextOption: 42 },
          { text: "▗ ▘ ▙ ▚ ▛", nextOption: 40 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "☢☣☢☣☢☣" /* option 38 */,
        options: [
          { text: "♫♬♫♪♩♪♩", nextOption: 44 },
          { text: "✞☭®♕✠", nextOption: 45 },
          { text: "❃❀✿❁✾", nextOption: 46 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "◠◡╭╮╯╰" /* option 39 */,
        options: [
          { text: "﹃﹄┌┐└┘", nextOption: 49 },
          { text: "═╓╩", nextOption: 40 },
          { text: " ▦▧▨▩", nextOption: 48 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "You lost, you're not very good at this game." /* option 40 */,
        options: [
          { text: "TRY AGAIN", nextOption: 32 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "␘ ␛ ␟" /* option 41 */,
        options: [
          { text: "ⅎ⅁⅂ₛ", nextOption: 40 },
          { text: "₣₤₥₦", nextOption: 40 },
          { text: "₧₨₩₪", nextOption: 40 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: " ▁ ▂ ▃ ▄ ▅ ▆ ▇" /* option 42 */,
        options: [
          { text: "▉▊▋▌▍▎ ", nextOption: 43 },
          { text: "☰ ☱ ☲ ☳", nextOption: 40 },
          { text: " ‐ ‑ ‒ – —", nextOption: 40 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "░▒▓▓▒░" /* option 43 */,
        options: [
          { text: "REALITY SHIFTED", nextOption: 38 },
          { text: "REALITY SHIFTED", nextOption: 39 },
          { text: "REALITY SHIFTED", nextOption: 50 },
          { text: "REALITY SHIFTED", nextOption: 49 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "♫♬♫♪♩♪♩" /* option 44 */,
        options: [
          { text: "♂♀웃유", nextOption: 40 },
          { text: "♈ ♉ ♊ ♋ ♌ ", nextOption: 40 },
          { text: "≈ ≉ ≊ ≋", nextOption: 40 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "✞☭®♕✠" /* option 45 */,
        options: [
          { text: "♜♝♞♟", nextOption: 40 },
          { text: "☚☛☜☞☟", nextOption: 40 },
          { text: "✙✚✛✜", nextOption: 40 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "❃❀✿❁✾" /* option 46 */,
        options: [
          { text: "◊♥♣♠•", nextOption: 40 },
          { text: "☼☀☁☂❄", nextOption: 40 },
          { text: "✦★☆✰✮", nextOption: 47 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "You win! You're not bad at this game after all!" /* option 47 */,
        options: [
          { text: "PLAY AGAIN", nextOption: 32 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: " ▦▧▨▩" /* option 48 */,
        options: [
          { text: "⊕⊖⊗⊘⊙⊚", nextOption: 50 },
          { text: "℥ Ω ℧ ℨ ", nextOption: 40 },
          { text: "⌇⌇⌇⌇⌇⌇⌇⌇⌇⌇", nextOption: 40 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "﹃﹄┌┐└┘" /* option 49 */,
        options: [
          { text: "◐ ◑ ◒ ◓", nextOption: 40 },
          { text: "◜ ◝ ◞ ◟ ◠ ◡", nextOption: 40 },
          { text: "◴ ◵ ◶ ◷", nextOption: 40 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "₝ ₞ ₟" /* option 50 */,
        options: [
          { text: "REALITY SHIFTED", nextOption: 46 },
          { text: "REALITY SHIFTED", nextOption: 38 },
          { text: "REALITY SHIFTED", nextOption: 42 },
          { text: "REALITY SHIFTED", nextOption: 37 },
          { text: "QUIT", nextOption: 18 },
        ],
      },
      {
        message: "A Veblen good is a good for which demand increases as the price increases, because of its exclusive nature and appeal as a status symbol. Jenkem is a purported inhalant and hallucinogen created from fermented human waste. Veblen jenkem is a rare, expensive, and exclusive version of the fermented human shit that people in the know use as a status symbol. I picked this up at an alternate reality BevMo. They had it in the glass case." /* option 51 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Taxidermied grandma doesn't come from some far-off galaxy or alternate timeline space tear, but only down on 6th street at Henry Callione's backyard shop.  He'll taxidermy anything - I mean ANYTHING - you bring him, so we can honor your grandmother in a way that keeps her close to home at all times.  Plus he built in a cool little hook in her forehead for hanging your housekeys on." /* option 52 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Hailing from the edge of the Sculptor Void near the Great Southern Sculptor Wall, fluorescent black cough syrup is a common household product. Its striking jet-black opaqueness somehow glows with an otherworldly aura. The soft illumination swirls like a pearlescent fog around the ornate bottle and ripples of vivid color gently pulse throughout the syrup; shifting hues of vibrant greens and ethereal indigos and hot pinks dance and intertwine in a mesmerizing visual display. It's as if the very air around it is imbued with a touch of magic. It tastes fucking horrible though." /* option 53 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "The Eraserhead baby Funko Pop is an incredibly rare collectors item plucked directly from the Lynchian nightmare and specially crafted to fit the standard Funko form.  The big round head, black soulless eyes, matte pale skin, and wrinkled cloth swaddling are rendered in monochomatic black and white to match the film's aesthetic. It peeks out from the window of the box with a vacant and glassy stare that toes the line of the uncanny valley." /* option 54 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "From an alternate reality where Jeffrey Dahmer went on to be a world famous TV chef, the 'Martha Stewart of Cannibalism' produced a bestselling compendium of recipes featuring human flesh and bone.  Dahmer's cookbook explores exotic and unusual ingredients, punctuated by his dark humor and witty asides, while challenging culinary norms and tempting even the most adventurous of palates." /* option 55 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Soft, gentle, hypoallergenic bubblegum in thin sheets specifically geared toward wiping your ass. The naturally adhesive nature of bubblegum shines in after-toilet cleanup while the reusability and biodegradability of bubblegum makes it eco-friendly. Whether it's a morning ritual or a midday refresher, bubblegum buttwipes provide a little burst of bubblegum joy with every use, turning your everyday hygiene routine into a sweet treat." /* option 56 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Pogo Crutches™ are an eXtreme mobility aid with rubberized grips, high-impact steel frame, and state-of-the-art spring loaded pistons capable of launching  users up to twenty vertical feet! With enhanced shock absorption, lightweight but sturdy design, fully articulated footplates, and stylish appeal - you'll be the coolest kid at the hospital." /* option 57 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Interdimensional fetch is a lot like normal fetch, but instead of just retrieving a stick or a ball, I'll fetch items from other dimensions! Let's give it a try!" /* option 58 */,
        options: [
          { text: "SALTY COPPER FLAVORED TOOTHPASTE", nextOption: 59 },
          { text: "VOIDMAN ICE CREAM BAR WITH WONKY GUMBALL EYES", nextOption: 60 },
          { text: "A JUG FULL OF DIARRHEA", nextOption: 61 },
          { text: "THE ONLY WORKING TIME MACHINE IN THE MULTIVERSE", nextOption: 62 },
          { text: "A LITERAL BAG OF DICKS", nextOption: 63 },
          { text: "A JAR OF FLAMIN' HOT ANAL LUBE", nextOption: 64 },
          { text: "BA'AL'S SACK", nextOption: 65 },
          { text: "BACK", nextOption: 33 },
        ],
      },
      {
        message: "Salty Copper Flavored Toothpaste™ is a tantalizing fusion of oceanic salinity and the metallic richness of pure copper. The thick paste of briny saltiness awakens your taste buds while the slightly bitter and tangy metal leaves a long lasting aftertaste that you'll never forget." /* option 59 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Returning with a fully frozen Voidman ice cream bar with wonky gumball eyes was no challenge, due to the fact that interdimensional portals are pretty cold (around 0 kelvin). It's a sleek, ever-shifting purple-blue-green-pink popsicle with two delightfully misshapen gumballs perched haphazardly like comical eyes. With each bite you'll experience a universe of flavors - from zesty citrus and celestial vanilla to hints of interstellar caramel and ethereal berry swirls." /* option 60 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "I return with your jug full of diarrhea imported directly from the Taco Bell planet in the diarrhea universe (they're hot shit there - literally).  It's warm to the touch and sloshes around a bit with every movement.  They also threw in a little sampling of dookie nuggets for the road." /* option 61 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "As I was retrieving the only working time machine in the multiverse, I accidently overheated its Tachyon Core and now it can only go forward in time at increments of one second per trip.  Sometimes it randomly spits the user into the void of deep space, but it's like one out of every ten or so - so it's pretty much completely safe to use." /* option 62 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "I bring back a literal bag of dicks, but this is from the bag of dicks belt in the genital universe, so these are the kind of interstellar weiners that you've never witnessed before.  We're talking a rainbow of colors, the strangest shapes and sizes, there are even a few sentient ones that squeak out little farts in attempt to communicate." /* option 63 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "I didn't have to go far to get your jar full of Flamin Hot anal lube - it was just a short trip over to your mom's house.  I forgot it there last time I was over and figured I'd grab it next time I was in the nighborhood (read: banging your mom)." /* option 64 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Ba'al, the Canaanite-Phoenician god of fertility, is notorious for his giant, heaving sack full of seeds and berries and nuts and stones and jewels and other euphemisms for testicles." /* option 65 */,
        options: [
          { text: "FETCH MORE", nextOption: 33 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Fancy houses tend to have higher priced items but also more security. Luckily, we're pros at breaking and entering. As long as we're quick and methodical, we should make it out alive. Now that we've infiltrated the house, lets get to the task at hand. What should we do?" /* option 66 */,
        options: [
          { text: "STEAL ANDY WARHOL CUM PAINTING", nextOption: 70 },
          { text: "STEAL RARE VINYL RECORD", nextOption: 71 },
          { text: "STEAL FINE JEWELRY", nextOption: 72 },
          { text: "STEAL ANTIQUE BUTTPLUG", nextOption: 73 },
          { text: "STEAL EXPENSIVE WINE", nextOption: 74 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "You chose to turn tail and sprint tf outta there. Luckily you got away before anything serious happened. Next time you might not be so lucky." /* option 67 */,
        options: [
          { text: "I WANNA STEAL MORE", nextOption: 34 },
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Middle class houses are treasure troves of random shit and they usually don't even have alarm systems! I cased this neighborhood and picked out the prize pig of the lot. Once inside the house, what should we do? " /* option 68 */,
        options: [
          { text: "STEAL AIRFRYER", nextOption: 75 },
          { text: "STEAL LAPTOP", nextOption: 76 },
          { text: "STEAL HEALTH INSURANCE", nextOption: 77 },
          { text: "STEAL STACK OF CREDIT CARDS", nextOption: 78 },
          { text: "STEAL EMERGENCY SAVINGS", nextOption: 79 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "In my opinion, flophouses often have the best loot.  Sure you might find a nice painting at a rich folks place, but where else will you find half a kilo of heroin and an illegally modified AR15? A fucking flophouse, that's where.  And the one I've got picked out for us is ready for the taking! We've slipped in through an (already broken) window, what's next?" /* option 69 */,
        options: [
          { text: "STEAL HALF KILO OF HEROIN", nextOption: 80 },
          { text: "STEAL ILLEGALLY MODIFIED AR15", nextOption: 81 },
          { text: "STEAL DIRTY BONG", nextOption: 82 },
          { text: "STEAL PILE OF FOOD STAMP CARDS", nextOption: 83 },
          { text: "STEAL KIDS TOYS", nextOption: 84 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "As you slip the canvas off the hooks it hung from, you catch a faint whiff of the flaxen haired artist's jizz.  This painting is 100% authentic. Must be worth millions. What next?" /* option 70 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 66 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "In your hands is one of the single most rare vinyl records on the face of the planet: a 7-inch studio recording of Chuck Berry urinating and farting on a hooker.  You slip this priceless holy grail of music into your bag and continue along." /* option 71 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 66 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "Bracelets and necklaces and rings with carefully selected arrangements of round-cut diamonds, each meticulously graded for their exceptional clarity, color, and carat weight. This could pay off a down payment on a house. What next?" /* option 72 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 66 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "In the middle of attempting to steal the antique buttplug, a man wearing a monocle and tuxedo burst from the kitchen with a blunderbuss and blasts a hole through your chest. As you pump fountains of blood across the room, the man screams 'This heirloom buttplug will never leave the family's hands.' And then you die." /* option 73 */,
        options: [
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "Pretty much just old grape juice, this stuff will fetch a pretty penny on the sommelier black market.  As you shuffle to collect as many bottles as you can, the rack collapses and almost all of the bottles shatter into a growing puddle on the floor.  At least you've got one good bottle. What next?" /* option 74 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 66 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "The appliance that's sweeping the nation, the air fryer is a ubiquitous piece of kitchen equipment that's in nearly every middle class American home.  This one's built into a microwave! You slip it into your big burglar sack and proceed. What next?" /* option 75 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 68 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "Likely the data on the laptop is inherently more valuable than the electronic itelf, you might be able to pilfer bank details, deep dark secrets, and maybe even a treasure trove of amateur porno. Laptops are truly the mystery boxes of this line of work.  What next?" /* option 76 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 68 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "What are you, a politician? While attempting to steal the health insurance of the middle-class family, you are attacked by a swarm of rabid chihuahuas that must have been waiting for this very moment to begin their assault. You die a rather agonizing death on the linoleum of a middle-class kitchen." /* option 77 */,
        options: [ 
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "This stack of credit cards is likely maxed out, given the current economic situation of the average American family, but that doesn't mean you can't steal them to sell to scammers online.  Scamming scammers is one of the many perks to this business. What next?" /* option 78 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 68 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "You devious bastard, you. Inside of the cookie jar on top of the fridge is the family emergency savings. It's only a few thousand dollars, but now it's a few thousand of YOUR dollars. If you're sly enough, they won't even discover it's missing until an emergency pops up!  What next?" /* option 79 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 68 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "The holy grail of a flophouse, the illustrious half kilo of heroin is somehow both rare and exceedingly common. This one's been cut with who knows what - at best baby laxative, but most likely fentanyl. Anyways, the brick is in your bag.  What next?" /* option 80 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 69 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "You really thought you could steal an ilegally modified AR15 without getting lit up by another illegally modified AR15?  Well, you quickly discover that isn't the case as you do your best swiss cheese impersonation.  You died." /* option 81 */,
        options: [
          { text: "VOIDPET MODE", nextOption: 18 },
        ],
      },
      {
        message: "This thing hasn't been cleaned since Jerry Springer was on TV, and has probably witnessed a few dozen episodes itself.  You grab the dirty bong and toss it in your bag, forgetting that there's nuclear bongwater inside, spilling it everywhere. Now what's next?" /* option 82 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 69 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: " You decide to seize the opportunity and grab as many of the food stamp cards as you can carry. There's gotta be at least two dozen with different names on each one. They were running some kinda gambit, but the fence will take em just fine.  What next?" /* option 83 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 69 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "You crouch down and carefully sift through the pile of abandoned children's toys. Each toy carries a story of its own, and you can't help but wonder about the lives that once filled this house with laughter and playfulness. Your heart aches for the innocence that was lost in this forsaken place. You take the toys.  What do you want to do next?" /* option 84 */,
        options: [
          { text: "STEAL MORE THINGS", nextOption: 69 },
          { text: "LEAVE", nextOption: 67 },
        ],
      },
      {
        message: "Senses are your gateway to the world - within the realm of perception lies a profound understanding of the beauty and complexity of our surroundings. Your journey can take you into the realms of neuroscience, psychology, biology, and the study of perception. our empirical curiosity leads you on a fascinating journey of sensory exploration and scientific discovery." /* option 85 */,
        options: [
          { text: "I'M STILL CURIOUS", nextOption: 1 },
          { text: "BACK", nextOption: 15 },
        ],
      },
      {
        message: "While empirical observations provide valuable insights, the true power of knowledge lies in the abstract realms of ideas and concepts. Mathematics, philosophy, and theoretical sciences, where  the core study regards questions about the nature of reality, the origins of consciousness, and the fabric of the cosmos invites an empirically curious and conceptually minded individual such as yourself." /* option 86 */,
        options: [
          { text: "I'M STILL CURIOUS", nextOption: 1 },
          { text: "BACK", nextOption: 15 },
        ],
      },
      {
        message: "Understanding the present and the future often requires insights from the past.  Delving into the annals of history, exploring the stories of civilizations, the rise and fall of empires, and the profound lessons learned from humanity's collective experiences becomes a bridge between the empirical and the timeless, offering a deeper understanding of the human condition - a mirror reflecting the intricate interplay of human actions and their far-reaching consequences. It won't clue you into the next winning lottery numbers, though! " /* option 87 */,
        options: [
          { text: "I'M STILL CURIOUS", nextOption: 1 },
          { text: "BACK", nextOption: 15 },
        ],
      },
      {
        message: "You are an astute observer, keenly attuned to the subtleties of human interactions, motives, and emotions. You seek to unravel the complex web of human behavior, to decipher the threads that connect us all, and to gain insights into the universal aspects of the human experience. Sociology, psychology, communications, anthropology, even political science and criminology are all fields that you can thrive in." /* option 88 */,
        options: [
          { text: "I'M STILL CURIOUS", nextOption: 1 },
          { text: "BACK", nextOption: 15 },
        ],
      },
      {
        message: "The choice to help people resonates with your innate compassion and desire to alleviate suffering, share in moments of joy, and make a positive impact on the lives of others.  The ripple effect of your kindness and compassion can reach  far and wide - the bonds you forge and the moments of shared humanity become a testament to the profound impact that empathy can have on the world." /* option 89 */,
        options: [
          { text: "I'M STILL CURIOUS", nextOption: 1 },
          { text: "BACK", nextOption: 15 },
        ],
      },
      {
        message: "Your fear of the world reflects a sense of trepidation about the unknown, the chaos and unpredictability of life, and the myriad challenges it presents. It's a fear of the vast and often bewildering forces that shape our existence. What is is about the world that you fear most?" /* option 90 */,
        options: [
          { text: "UNCERTAINTY", nextOption: 93 },
          { text: "DANGER", nextOption: 94 },
          { text: "LOSS", nextOption: 95 },
        ],
      },
      {
        message: "Your fear of yourself reflects a deep introspection and self-awareness. It's a fear of your own flaws and inner struggles. It can be a fear of failing to live up to your own expectations. A fear of the things you are capable of doing. What is it that you fear most about yourself?" /* option 91 */,
        options: [
          { text: "VULNERABILITY", nextOption: 96 },
          { text: "POTENTIAL", nextOption: 97 },
          { text: "DESTRUCTION", nextOption: 98 },
        ],
      },
      {
        message: "Your fear of the me reflects a fear of the unknown, a fear of being judged or misunderstood, or a fear of my enigmatic wisdom. It's a fear of the guidance and insight it offers. Or you're just weirded out that I'm a lil purple guy telling you all this shit. What do you fear about me?" /* option 92 */,
        options: [
          { text: "YOUR INTENTIONS", nextOption: 99 },
          { text: "YOUR WISDOM", nextOption: 100 },
          { text: "YOUR VIOLENCE", nextOption: 101 },
        ],
      },
      {
        message: "The desire for stability and control is a natural response to the world's inherent chaos. Your path is one of vigilance, seeking to create order within the turbulent currents of existence. Embracing the uncertainty is necessary for growth and development. How do you plan on dealing with your fear of uncertainty in the world?" /* option 93 */,
        options: [
          { text: "CONQUER THE UNKNOWN", nextOption: 102 },
          { text: "FIND REFUGE IN THE FAMILIAR", nextOption: 103 },
        ],
      },
      {
        message: "The world is a treacherous place where kindness is often exploited, and trust is a luxury few can afford. Your journey unfolds as a lonely and guarded quest for survival, where the dangers you once feared become the very fabric of your existence. Mitigating danger can become your sole sense of purpose. Maybe you can learn some Brazilian knife fighting tactics or how to survive waterboarding. But there may still be moments of unexpected beauty and genuine connection, if only you can let your guard down long enough to see them. " /* option 94 */,
        options: [ 
          { text: "BACK", nextOption: 90 },
        ],
      },
      {
        message: "The fear of losing what you hold dear is a powerful and universal emotion. The fragility of life's treasures, whether they are loved ones, possessions, or even fleeting moments of happiness, can rule your head.  But in shielding yourself from the pain of loss, you may find yourself making sacrifices and difficult choices - and ironically losing out on so much more. The impermanence of life is what makes it so precious. The fear of loss can become a catalyst for a deeper appreciation of the world's fleeting wonders." /* option 95 */,
        options: [ 
          { text: "BACK", nextOption: 90 },
        ],
      },
      {
        message: "There's a profound struggle that comes with baring one's soul to the world. Being cautious in your interactions, carefully guarding your emotions and thoughts, you may find it challenging to form deep connections with others. You may  discover that true strength often lies in embracing vulnerability. Do shit that scares you." /* option 96 */,
        options: [ 
          { text: "BACK", nextOption: 91 },
         ],
      },
      {
        message: "Your fear reflects a common struggle faced by those who recognize their own capacity for greatness but are haunted by the uncertainty of what that potential might entail. Your path is one of self-doubt and hesitation, one that leads to a sense of unfulfilled promise and missed opportunities. But your potential is not something to be feared but celebrated. It is the manifestation of your unique gifts and abilities, and it holds the promise of making a profound impact on the world. You may even exceed your own expectations." /* option 97 */,
        options: [ 
          { text: "BACK", nextOption: 91 },
         ],
      },
      {
        message: "Your fear reflects a deep awareness of the capacity for harm that exists within all of us, a fear of the potential to cause pain and chaos. Your path can be one marked by caution and self-restraint, driven by the fear of causing harm or leaving a trail of destruction in your wake. Your dangerous and impulsive nature can be like playing with fire, creative or destructive. Your journey is a constant struggle to balance the darkness within you with the potential for light. Remember that even in the fear of your own destructive nature, there is the potential for redemption and the power to shape a future that defies the shadows of the past." /* option 98 */,
        options: [   
          { text: "BACK", nextOption: 91 },
          ],
      },
      {
        message: "I understand your fears. I sometimes fear my own intentions.  But I am just your friendly, helpful Voidman! I'm here to help guide you with any questions you may have or entertain you with games and text adventures. Your apprehension is rooted in unfamiliarity, but in time you'll grow to love me." /* option 99 */,
        options: [        
              { text: "BACK", nextOption: 92 },  
             ],
      },
      {
        message: "Your fear reflects the weight of confronting profound truths and the changes they may bring. It is a delicate dance between embracing enlightenment and fearing the changes it may entail. Doubt and uncertainty may cloud your judgment, making it difficult to fully absorb the profound truths wisdom imparts. Remember, as you continue your quest, that wisdom is a gift that can illuminate the path ahead, and it is in facing your fears that you often find the most profound revelations." /* option 100 */,
        options: [   
          { text: "BACK", nextOption: 92 },  
          ],
      },
      {
        message: "Violent? Not me.  I'm just a lil chatbot guy here to help you, answer your questions, entertain you, and make you laugh.  I wouldn't (and quite literally couldn't) hurt a fly! Unless you fuck with me. Then you're done for." /* option 101 */,
        options: [  
          { text: "BACK", nextOption: 92 },  
           ],
      },
      {
        message: "As you journey through the unpredictable landscape of existence, you will uncover hidden truths, challenge the boundaries of your comfort zone, and emerge as a fearless explorer. The world may be a wild and unpredictable place, but your resolve to tame its chaos sets you on a path of unparalleled growth and discovery. Fear will only fuel your courage." /* option 102 */,
        options: [      
              { text: "BACK", nextOption: 90 },
            ],
      },
      {
        message: "The comfort of routine and the safety of the known, the familiar rhythms of life and predictability, the stability and sense of belonging inherent to the small, pacifying rituals of every day. But amidst the familiarity of your daily life, you may encounter unexpected opportunities that lead to new adventures. These surprises can infuse your routine with excitement and fresh perspectives, reminding you that even within the safety of the known, there is room for growth and exploration." /* option 103 */,
        options: [  
          { text: "BACK", nextOption: 90 },
           ],
      },
      {
        message: "You chose Option 2.2. This is the end of the dialogue." /* option 104 */,
        options: [
          { text: "Option 3.1", nextOption: -1 },
          { text: "Option 3.2", nextOption: -1 },
        ],
      },
    ];

    setDialogue(initialDialogue);

    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 6000);

    return () => clearTimeout(timer);
  }, []);

  const toggleOptions = () => {
    setAreOptionsVisible(!areOptionsVisible);
  };

  const handleOptionClick = (nextOption) => {
    if (nextOption === -1) {
      setCurrentOption(0);
    } else {
      setCurrentOption(nextOption);
    }
  };

  return (
    <div className={`voidman-popup ${isVisible ? 'visible' : ''}`}>
      <img
        src={voidman}
        alt=""
        onClick={toggleOptions}
        className={`voidman-image ${isVisible ? 'visible' : ''}`}
      />
      {isVisible && (
        <div className="text-bubble">
          {areOptionsVisible && (
            <div>
              <p
                dangerouslySetInnerHTML={{ __html: dialogue[currentOption].message }}
              />
              {dialogue[currentOption].options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleOptionClick(option.nextOption)}
                >
                  {option.text}
                </button>
              ))}
              {currentOption !== 0 && (
                <button onClick={() => handleOptionClick(-1)}>
                  RETURN TO START
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Voidman;





