import React, { useState } from 'react';
import './Contact.css';
import freakcontact from './Img/FREAKBAiTSTREETTEAM.webp';
import freaksoul from './Img/FREAKBAiTSTREETTEAMSOUL.webp';

function Contact() {
  const [image, setImage] = useState(freakcontact);

  const handleImageChange = () => {
    setImage(freaksoul);
  };

  const handleImageReset = () => {
    setImage(freakcontact);
  };

  return (
    <div className='contact-container'>
      <div className='contact-body'>
        Contact FREAKBAiT via email at
        <br/>
        <a href='mailto:FREAKBAiT@freakbait.com?subject=I%20Love%20FREAKBAiT' target='_blank' rel='noopener noreferrer'>
          <span className='freak-text'>freakbait@freakbait.com</span>
        </a>
        <br/><br/>
        <span className='freak-text'>CLICK BELOW TO SIGN UP FOR THE FREAKBAiT STREET TEAM</span>
        <br/>(LIMITED SPACES AVAILABLE)
        <br/>▼▼▼▼▼▼
        <div className='contact-image'>
          <a
            href='https://docs.google.com/forms/d/e/1FAIpQLScfnmjGtvz9VWA1oV15UffQ1EFPmntTXSteaTTDA6zD924PIw/viewform'
            target='_blank'
            rel='noreferrer'
            alt='FREAKBAiT Street Team'
            onMouseEnter={handleImageChange}
            onMouseLeave={handleImageReset}
          >
            <img src={image} alt='JOiN THE FREAKBAiT CULT' style={{ maxWidth: '100%', height: 'auto' }}/>
          </a>
          <br/><br/>
        </div>
      </div>
    </div>
  );
}

export default Contact;
