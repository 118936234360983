import React from 'react'
import "./About.css"

function About() {

return (
    <div className='about-body'>
      <div className='about-intro'>
        CRADLED IN THE CUSHIONS OF MY COUCH, I'M SURFIN' CHANNELS SUNSET SNUFFED BY THE HORIZON LIKE BURNING CANDLES...
      </div>
        <div className='about-text'>

            Bathed in the static glow of re-re-re-recorded VHS late-night scrambled porn, <span className='freak-text'>FREAKBAiT</span> plinks away at an ensemble of corrupted toys and day-glo machines - conjuring a psychovisual and sonic cacophony that spreads your third eye wide like a whore's thighs and overdoses your childhood with gallons of bad acid - leaving your mind floating through the empty void of psychic space as an interstellar Mary Celeste.
            <br/><br/>
            <span className='freak-text'>FREAKBAiT</span> is a California-based multimedia creator that emerged circa 2016 as a primordial artist collective of one, creating hallucinatory multimedia exhibitions and experimental psychedelic noise in relative obscurity.  After a brief hiatus between 2018-2020, <span className='freak-text'>FREAKBAiT</span> returned to the internet to spread chaos and discord throughout the world via viral micromedia.  The TODAY'S MISSION series has seen such success that it has spread virally worlwide.
            <br/><br/>
            <span className='freak-text'>FREAKBAiT</span> has been featured in as diverse publications such as internet-meme aggregator <a href="https://www.youtube.com/watch?v=-tiq4efTKuM" target="_blank" rel="noopener noreferrer">Know Your Meme</a>, French newspaper <a href="https://www.lemonde.fr/pixels/article/2023/10/02/sur-tiktok-le-profil-mission-du-jour-et-ses-videos-virales-accuses-de-plagiat_6192049_4408996.html">Le Monde</a>, Slovakian news <a href='https://brainee.hnonline.sk/notsorry/news/spolocnost/slovensko/96076387-v-kosiciach-sa-objavili-plagaty-slubujuce-vykup-ludskej-koze-za-peniaze-zistovali-sme-kto-stoji-za-bizarnou-reklamou' target="_blank" rel="noopener noreferrer">Brainee.sk</a>, Montreal film festival <a href='https://www.cabaneasang.com/en/directors/289/freak-bait' target="_blank" rel="noopener noreferrer">Cabane à Sang</a>, as well as on the walls of countless bathroom stalls across the world.
            <br/><br/>
            <span className='freak-text'>FREAKBAiT</span> has released several sold-out zines, multiple handbooks, and two bestselling books (<a href='https://freakbait.myshopify.com/products/freakbait-the-book' target="_blank" rel="noopener noreferrer">FREAKBAiT: The Book</a> and <a href='https://freakbait.myshopify.com/products/todays-mission-the-book' target="_blank" rel="noopener noreferrer">TODAY'S MISSION: The Book</a>). He's always working on something new.
            <br/><br/>
            <span className='freak-text'>FREAKBAiT</span> has worked with businesses, brands, artists, entertainers, and creatives of all sorts (such as <a href="https://www.instagram.com/p/CjlZX3PJs3U" target="_blank" rel="noopener noreferrer">Meow Wolf</a>, <a href='https://www.instagram.com/p/CsETz3fglNy/' target="_blank" rel="noopener noreferrer">Meta/Instagram</a>, <a href="https://www.youtube.com/watch?v=tUVIq8FoLDU" target="_blank" rel="noopener noreferrer">Brick + Mortar</a>, and <a href="https://www.tiktok.com/@flagmanband/video/7120328163577711915" target="_blank" rel="noopener noreferrer">Flagman</a> among others) producing advertising and social media campaigns, VJ loops, custom animations, logos, album covers, Spotify canvases, and more.  He even designed and coded the very website you're reading this on.
            <br/><br />
            If you are interested in commissioning work, please <a href = 'mailto:FREAKBAiT@freakbait.com?subject=Commission%20Request' target="_blank" rel="noopener noreferrer">CONTACT ME</a>.
        </div>

        <div className='faq-section'>
          <div className='faq-title'>
        <h2><span className='freak-text'>FREAKBAiT</span> FAQ</h2>
        <h3>FREAKuently Asked Questions</h3></div>
        <br />
        <button className="faq-question">How do you make your videos?</button>
        <div className='faq-answer'>
           I model and animate with <a href="https://www.blender.org/" target="_blank" rel="noopener noreferrer">Blender</a> using a plethora of resources such as <a href="https://www.mixamo.com/" target="_blank" rel="noopener noreferrer">Mixamo</a>, <a href="https://sketchfab.com/" target="_blank" rel="noopener noreferrer">Sketchfab</a>, <a href="https://polyhaven.com/" target="_blank" rel="noopener noreferrer">Poly Haven</a>, <a href="https://www.poliigon.com/" target="_blank" rel="noopener noreferrer">Poliigon</a>, <a href="https://fspy.io/" target="_blank" rel="noopener noreferrer">fSpy</a>, and <a href="https://github.com/agmmnn/awesome-blender" target="_blank" rel="noopener noreferrer">github</a>. I use Adobe Photoshop for textures & graphic design and After Effects for editing & effects processing.  I then route it through a series of analog effects equipment (including a diy Karl Klomp dirty mixer and beat-up Sony SLV N1 VCR fed with old RCA and Maxell VHS) and into a cheap no-brand capture card back to my PC.  I do any final cleanup and color correction in After Effects before uploading it to the internet.
        </div>
        <br />
        <button className="faq-question">How do you make your music?</button>
        <div className="faq-answer">
            I produce all music and sound design featured in my videos. Most of it is made on a Tascam Portastudio MKIII cassette recorder routed through a Zoom L12 and digitized via Audacity on a PC. I primarily use Memorex Type II cassette tapes. 
            <br /><br />
            Music gear I've recorded with includes: Squire Telecaster with Seymour Duncan HotRails, Ibanez bass with GHS precision flats, Korg Volca Sample, Teenage Engineering PO-33 & PO-20, Arturia Microbrute, Arturia Keystep, Behringer Model D, Cat Full of Ghosts Yowler, Arcano II, Moog Theremini, Otamatone, Bastl Kastle & Kastle B.Drum, Bastl Microgranny, Sonicware Liven 8bit Warps, IK Multimedia UNO synth, Alesis Mesh Nitro ekit (oftentimes routed through Toontrack EZDrummer2), modified prison telephone, Roland VT3, modified Fart Piano, modified ByronStatics cassette player, Vox AC15, Peavey MAX 115, Pyle PDMIC78, and an innumerable amount of effects boxes (both purchased and handmade), converters, circuitbent toys and gizmos and doodads aplenty.
            <br /><br />
            I record my narration with a modified prison phone (the speaker has been reverse-soldered to act as a microphone) routed through a Behringer DI20 and into a Tascam Portastudio MKIII, then sent into a Zoom L12 and recorded in Audacity.
            <br /><br />
            I release my music under a multitude of different names including <a href="http://www.mookielaka.com" target="_blank" rel="noopener noreferrer">Mookielaka</a>, <a href="http://www.spoogenackin.com" target="_blank" rel="noopener noreferrer">Spooge Nackin</a>, <a href="https://www.youtube.com/user/NSFHipHop" target="_blank" rel="noopener noreferrer">NSF</a>, <a href="https://0011011001111000.bandcamp.com/" target="_blank" rel="noopener noreferrer">0011011001111000</a>, <a href="https://feffyfussock.bandcamp.com/" target="_blank" rel="noopener noreferrer">Feffy Fussock</a> and too many more to list here.
            <br /><br />
            I have also performed in bands such as The Mother Vines, Super Senior, Motels At Midnight, The Slumcuts, and various one-off collaborative projects.
        </div>
        <br />
        <button className="faq-question">How do you perform live shows?</button>
        <div className="faq-answer">
            My live audiovisual shows are performed using Jonas Bers' CHA/V 3.0 & VGA4EVA, ChromaCauldron Mainbow, Freedom Enterprise's Mismatcher01 REV.D, Andrei Jay's WaaavePool, a Roland Edirol V4, a Sony SLV N1 VCR, several cheap nightvision cameras, my own custom dirty mixer, and a stack of mixed-multimedia I recorded to VHS tapes shot through a cheap no-brand projector.  I typically perform along with live music, but have also done video installations at events.
        </div> 
        <br />
        <button className="faq-question">What's with all the pink buttplugs?</button>
        <div className="faq-answer">
           I think buttplugs are hilarious; these lil pink plastic christmas trees someone shoves up their ass is just pure novelty to me.  
           <br /><br />
           It started from the mission video "see how many random items you can get someone to hold during conversation" and I wanted to give the charcater something absurd to hold, so I animated an armful of brightly colored buttplugs.  When the comments took hold, I knew that I had to now hide buttplugs in my work as an easter egg for all the fans.  After time, it became a fun lil FREAKBAiT trope.  In fact, there's even a few buttplugs hidden in <a href="https://freakbait.myshopify.com/products/deathbait-trve-kvlt-edition" target="_blank" rel="noopener noreferrer">DEATHBAiT tshirt</a> if you can spot em!
        </div>
        <br />
        <br />
        <button className="faq-question">How can I help support?</button>
        <div className="faq-answer">
            <p>You can help support by <a href="https://freakbait.myshopify.com/" target="_blank" rel="noopener noreferrer">buying my merch</a>, becoming a <a href="https://www.patreon.com/freakbait" target="_blank" rel="noopener noreferrer">patron</a>, or simply just donating through <a href="https://www.paypal.com/paypalme/freakbait" target="_blank" rel="noopener noreferrer">paypal</a>, <a href="https://account.venmo.com/u/freakbait" target="_blank" rel="noopener noreferrer">venmo</a>, <a href="https://cash.app/$Freakbait" target="_blank" rel="noopener noreferrer">cashapp</a>, or <a href="https://www.usps.com/shop/money-orders.htm" target="_blank" rel="noopener noreferrer">carrier pigeon</a>. I appreciate any and all help that enables me to continue creating content.</p>
        </div>
        <br />
        <button className="faq-question">Do you do commissions?</button>
        <div className="faq-answer">
          <p>Yes, I do graphic design, custom multimedia, social media advertising, album covers, sound design, and more. Currently I have a backlog of clients so the turnaround time is a bit longer than usual. <a href = 'mailto:FREAKBAiT@freakbait.com?subject=Commission%20Request' target="_blank" rel="noopener noreferrer">CONTACT ME</a> for more information.</p>
        </div> 

    </div>
  </div>
  )
}

export default About
