import React from 'react'
import YoutubeEmbed from '../../Components/YoutubeEmbed/YoutubeEmbed'
import './Music.css'

function Music() {

  return (
    <div className='music-container'>
      <YoutubeEmbed embedId='G4kzkS0uKKQ' /><br/>
      <div className="music-title">
        MOOKIELAKA
      </div>
      <div className='music-body'><br/>
        A multigenre cornucopia of sound, Mookielaka serves as the "catch-all" moniker under which most current FREAKBAiT tunes are released.  Ranging from several compiled volumes of library music featured in FREAKBAiT videos (numbering over 100 tracks) to a growing repertoire of singles from the upcoming Mookielaka debut album <b>SYNONYM FOR PROFANITY</b>, this musical rabbithole travels to the core of the earth.
        <div className='music-quote'><br/>
          YOU FEEL ALONE NOW? JUST WAIT UNTIL YOU'RE OLDER <br/>
          AND CROSSING OUT NAMES INSIDE YOUR PHONE BOOK <br/>
          SURE THE ROBOTS WON, IT'S ALL JUST NOVELTY <br/>
          BECAUSE NOTHING'S MORE HUMAN THAN TECHNOLOGY <br/>
        </div>
        <div className='music-links'>
          <div className='link-column'>
            <a href="http://www.mookielaka.com" target="_blank" rel="noreferrer" alt="mookielaka website">mookielaka.com</a><br/>
            <a href="https://www.youtube.com/channel/UCsHJzncu-NNSuSEbIHBDuYw" target="_blank" rel="noreferrer" alt="Mookielaka Youtube">Mookietube</a>
          </div>
          <div className='link-column'>
            <a href="https://open.spotify.com/artist/718SXedgAIcd8W2vbRajHa" target="_blank" rel="noreferrer" alt="Mookielaka on Spotify">Spotify</a><br/>
            <a href="https://www.instagram.com/mookielaka/" target="_blank" rel="noreferrer" alt="Mookielaka Instagram">Mookiegram</a><br/>
          </div>
        </div>
      </div>
      <br/>
      {/* <YoutubeEmbed embedId='QPuy05PsqUc' />
      <br/> */}
      <YoutubeEmbed embedId='qYg94_BX5b0' /><br/>
      <div className="music-title">
        SPOOGE NACKIN
      </div>
      <div className='music-body'><br/>
        Closet punk to shock yer mama! A single EP recorded entirely in a San Francisco studio apartment walk-in closet on a Tascam Portastudio 424 MKIII with an assembly of cheap instruments and diy pedals.  Old school lofi punk straight from the needle-littered Bay Area gutters, jammed into your eardrums like a plastic-handled screwdriver covered in cassette tape.
        <div className='music-quote'><br/>
          EMPTIED OUT MY POCKETS AND SHOVELED UP A MOUND <br/>
          BURIED ALL THAT MONEY AND THREW MY GUN INTO THE GROUND <br/>
          LEFT THE QUICKLY DIMMING HORIZON BEHIND ME, ABANDONED THE SUN AT SUNDOWN <br/>
          COMMITTED TO THE COURSE OF THE CRISIS, NOW I'M NOT FUCKIN' AROUND <br/>
        </div>
        <div className='music-links'>
          <div className='link-column'>
            <a href="http://www.spoogenackin.com" target="_blank" rel="noreferrer" alt="Spooge Nackin website">spoogenackin.com</a><br/>
            <a href="https://www.youtube.com/channel/UCGpgv1FLR071So8tTBzPITA" target="_blank" rel="noreferrer" alt="Spooge Nackin Youtube">SpoogeTube</a>
          </div>
          <div className='link-column'>
            <a href="https://open.spotify.com/artist/26wQeE8jNC7XaRM49myXJV" target="_blank" rel="noreferrer" alt="Spooge Nackin on Spotify">Spoogify</a><br/>
            <a href="https://spoogenackin.bandcamp.com/album/spooge-nackin" target="_blank" rel="noreferrer" alt="Spooge Nackin Bandcamp">Spoogecamp</a><br/>
          </div>
        </div>
      </div>
      <br/>
      <YoutubeEmbed embedId='w0XIKEhQ5Sk' /><br/>
      <div className="music-title">
        FREAKBAiT
      </div>
      <div className='music-body'><br/>
        Industrial noisepunk-hop filtered through a psychedelic sieve and churned out of a lofi mishmash of offkilter electronic excursions into depravity.  FREAKBAiT's stage shows incorporate a menagerie of blinking-lights gizmos and horrorshow projections and rats-nest cables strewn about the floor like a caveman in spaceship.  The energy of '84 Butthole Surfers and aesthetic of '90s Nickelodeon (on bad acid) come together under the watchful eye of a blowup-doll-masked madman in a pink suit. 
        <div className='music-quote'><br/>
          YOU'LL FIND THE CANARY IS LONG SINCE DECAYED <br/>
          LIFE FLICKERED THE LAST FRAMES OF A DREAM <br/>
          THE ONLY CONTRAST BETWEEN THE WOMB AND THE GRAVE <br/>
          ARE THE BOUNDARIES DISSOLVED IN BETWEEN
        </div>
        <div className='music-links'>
          <div className='link-column'>
            <a href="http://www.freakbait.com" target="_blank" rel="noreferrer" alt="FREAKBAiT website">FREAKBAiT.com</a><br/>
          </div>
          <div className='link-column'>
            <a href="https://freakbait.bandcamp.com/releases" target="_blank" rel="noreferrer" alt="FREAKBAiT Bandcamp">Bandcamp</a><br/>
          </div>
        </div>
      </div>
      <br/>
      <YoutubeEmbed embedId='k1Z0PEMFiGI' /><br/>
      <div className="music-title">
        NSF
      </div>
      <div className='music-body'><br/>
        Dilla meets Bava in a rhythmic phantasmagoria of sex and death, handcrafted with a dusty MPC and a crate full of dollar bin records. Sample-based "lofi hiphop" in the spirit of Blockhead, DJ Shadow, and J Dilla before the wave grew and collapsed.  Over ten albums of instrumental beats to smoke to, vibe to, and relax to. <br/>RIP NSF 💀
        <div className='music-quote'><br/>
          IF IT WASN'T FOR THE CUSTOMERS, I SWEAR THIS JOB I'D LOVE IT <br/>
          WORKIN' WITH THE GENERAL PUBLIC, SINGING "TAKE THIS JOB AND SHOVE IT" <br/>
          BUT IT FILLS MY BANK ACCOUNT TO PAY MY COUNTLESS BILLS, SO FUCK IT <br/>
          I GUESS I'LL KEEP ON SHOWING UP, RELUCTANT, TO THE JOB I'M STUCK WITH
        </div>
        <div className='music-links'>
          <div className='link-column'>
            <a href="https://www.youtube.com/user/NSFHipHop" target="_blank" rel="noreferrer" alt="NSF Youtube">Youtube</a><br/>
          </div>
          <div className='link-column'>
            <a href="https://open.spotify.com/artist/3pm4zv5DBVwlJ0wMhofLZy" target="_blank" rel="noreferrer" alt="NSF Spotify">Spotify</a><br/>
            <a href="https://nsfhiphop.bandcamp.com/" target="_blank" rel="noreferrer" alt="NSF Bandcamp">Bandcamp</a>
          </div>
        </div>
      </div>
      <br/>
      <YoutubeEmbed embedId='u6kNE2F1q8U' /><br/>
      <div className="music-title">
        0011011001111000
      </div>
      <div className='music-body'><br/>
        Once called "a snuff film, a haunted house, and an elaborate novel... all rolled up in one", 0011011001111000 (or [6x]) is a fully interactive treasure-trove of altered-state multimedia - distorted, processed, deconstructed and reassimilated into mind-bending flash films, subliminal assaults and shifting kaleidoscopic journeys. Caught between a surreal nightmare and warped mindtrip, this project presents an immersive journey through hypnagogic insomnia and psychological breakdown - complete with hidden features, puzzles and "trip-tools" galore.<br/>
        ੳੴ • hεαdph⊕ηεš rες⊕mmεηdεd • ੳੴ

        <div className='music-quote'><br/>
          ▞☓⬒⋢∥℣∥⋣⬒☓▚ <br/>
          ᙉᙉᙉᙁᙉᙉᙉᙁᙉᙉᙉᙁᙉᙉᙉ <br/>
          B-33920 4A 3R 14L PO3476<br/>
          ○◙◙◙◙◙◙◙◙◙○
        </div>
        <div className='music-links'>
          <div className='link-column'>
            <a href="https://www.youtube.com/user/0011011001111000" target="_blank" rel="noreferrer" alt="0011011001111000 Youtube">Youtube</a><br/>
            <a href="https://www.discogs.com/artist/3466594-0011011001111000" target="_blank" rel="noreferrer" alt="0011011001111000 Discogs">Discogs</a><br/>
          </div>
          <div className='link-column'>
            <a href="https://acloserlisten.com/tag/0011011001111000/" target="_blank" rel="noreferrer" alt="A Closer Listen">A Closer Listen</a><br/>
            <a href="https://0011011001111000.bandcamp.com/" target="_blank" rel="noreferrer" alt="0011011001111000 Bandcamp">Bandcamp</a>
          </div>
        </div>
      </div>
      <br/>
      
    </div>
  )
}

export default Music