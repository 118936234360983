import React, { useState } from "react";
import FreakFiles from "../../Pages/FreakFiles/FreakFiles"
import Exhibit from "../../Pages/FreakFiles/ExhibitCabinet"
import "./Overlay.css";

function Overlay(props) {
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [freakfiles, setFreakfiles] = useState(false)
  const [exhibit, setExhibit] = useState(false)

  // User Login info
  const database = [
    {
      username: "freakbait",
      password: "redherring"
      
      //you really thought it would be that easy?
      //this is just the mouth of the rabbithole, my friend
      
    },
  ];

  const errors = {
    uname: "username not found",
    pass: "invalid password"
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    // Find user login info
    const userData = database.find((user) => user.username === uname.value);

    // Compare user info
    if (userData) {
      if (userData.password !== pass.value) {
        // Invalid password
        setErrorMessages({ name: "pass", message: errors.pass });
      } else {
        setIsSubmitted(true);
      }
    } else {
      // Username not found
      setErrorMessages({ name: "uname", message: errors.uname });
    }
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>username </label>
          <input type="text" name="uname" required />
          {renderErrorMessage("uname")}
        </div>
        <div className="input-container">
          <label>password </label>
          <input type="password" name="pass" required />
          {renderErrorMessage("pass")}
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
        <div className="button-container">
          <button className='console-btn'
              onClick={closeReset}>
                   Exit
          </button>
        </div>
      </form>
    </div>
  );

  //close button
  function closeReset() {
  props.setMainframe(false);
  props.setOverlay(!Overlay);
}

//open FREAKFiLES
function freakOpen() {
  setFreakfiles(!freakfiles)
}

//open EXHiBit CABiNET
function exhibitOpen() {
  setExhibit(!exhibit)
}


  return (
    <div className="overlay">
      <div className="login-form">
        <div className="title">FREAKBAiT MAINFRAME v4.20:69</div>
        {isSubmitted ? 
        <>
          <div>User is successfully logged in</div> 
          <div className="button-container">
          <button className='console-btn'
              onClick={freakOpen}>
                   FREAKFiLES
          </button>
          </div>
          <div className="button-container">
          <button className='console-btn'
              onClick={exhibitOpen}>
                   EXHiBiT CABiNET
          </button>
          </div>
          <div className="button-container">
          <button className='console-btn'
              onClick={closeReset}>
                   Exit
          </button>
          </div>
        </>
        : renderForm}
        {freakfiles ? 
          <FreakFiles /> : null }
        {exhibit ?
         <Exhibit /> : null }
      </div>
      <div>IF YOU HAVE TO ASK FOR THE USERNAME AND PASSWORD<br></br>
      YOU ALREADY LOST BEFORE YOU  STARTED<br></br>
      GIVE UP NOW AND FORGET YOU WERE EVEN HERE</div>
     </div>
  );
}

export default Overlay;
