import React, { useState, useEffect } from "react";
import "./PopupModal.css";

const PopupModal = ({ isShowing, setIsShowing, title, children }) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setShowPopup(isShowing);
  }, [isShowing]);

  const closePopup = () => {
    setShowPopup(false);
    setIsShowing(false);
  };

  return (
    <>
      {showPopup && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{title.toUpperCase()}</h4>
                <button className="modal-button" onClick={closePopup}>
                  ×
                </button>
              </div>
              <div className="modal-body">{children}</div>
              <div className="modal-footer">
                <button className="okay-button" onClick={closePopup}>
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupModal;
