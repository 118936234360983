import React from 'react'
import './Splash.css'
import Line from './Line';

const startingList = [
  "Logging IP address...", 
  "Stealing your identity...", 
  "Installing Norton Antivirus...", 
  "Reticulating splines...", 
  "Texting your mother...", 
  "Harvesting meta-organs...",
  "Capturing facial data...",
  "Emptying bank account...",
  "Giving your grandma a stroke...",
  "Deleting system32...",
  "Logging keystrokes...",
  "Redownloading internet...",
  "Applying free AOL hours...",
  "Disabling VPN...",
  "Harshing your mellow...",
  ":(){ :|:& };:",
  "Spoofing emails...",
  "Deciphering search history...",
  "Triangulating location...",
  "Vending personal data...",
  "Hiding in your walls...",
  "Initializing 5G neural scrambler...",
  "Monitoring calls...",
  "Contacting your personal FBI agent...",
  "Charging credit cards...",
  "Immanentizing the eschaton...",
  "Fermenting jenkem...",
  "Spinning violently around the y-axis...",
  "Pay no attention to the man behind the curtain...",
  "Please wait while the little elves draw your map...",  
  "Reconfoobling energymotron...",
  "Following the white rabbit...",
  "Counting backwards from infinity...",
  "I'm sorry Dave, I can't do that...",
  "Should have used a compiled language...",
  "Connecting Neurotoxin Storage Tank...",
  "Initializing Rokos Basilisk...",
  "Constructing additional pylons...",
  "Dividing by zero...",
  "Cracking military-grade encryption...",
  "Twiddling thumbs...",
  "Collapsing superpositions...",
  "Installing dependencies...",
  "Mining shitcoin...",
  "Downloading more RAM...",
  "Pushing pixels...",
  "Debugging Debugger...",
  "Downloading Downloader...",
  "Updating Updater...",
  "Still faster than Windows update...",
  "Converging tachyon pulses...",
  "Adjusting dilithium crystal converter assembly...",
  'You are number 346291 in the queue...',
  "Reversing the shield polarity...",
  "Adding hidden agenda...",
  "Aligning covariance matrices...",
  "Deciding what message to display next...",
  "Gesticulating mimes...",
  "Locating the required gigapixels to render...",
  "Moving sattelite into position...",
  "Checking the gravitational constant in your locale...",
  "Loading infinite monkeys...",
  "Uploading porn to your hard drive...",
  "Remapping keyboard bindings...",
  "Insert quarter...",
  "Waiting for sysadmin to hit enter...",
  "Waiting for Godot...",
  "Notifying field agents...",
  "Negotiating for WiFi password...",
  "Deterministically simulating the future...",
  "A fatal exception has occurred...",
  "Generating ad content...",
  "Reconfiguring data for your IQ...",
  "Creating Universe (this may take some time)...",
  "Reality.sys corrupted. Reboot universe [y/n]?",
  "Commencing infinite loop...",
  "Calculating dimensionless physical constants...",
  "Violating Copernican principle...",
  "Reconciling general relativity and quantum mechanics...",
  "Renegotiating baryon asymmetry...",
  "Calculating 3-manifold comoving space topology...",
  "Nucleosyhtesizing rare isotopes...",
  "Prepondering enantiomers...",
  "Performing loophole-free Bell test experiment...",
  "Λ0/b→J/ψK-p",
  "Generating unsolvable koans...",
  "Quantizing time crystals...",
  "Loading loading messages...",
  "Centralizing the processing units...",
  "Randomizing memory access...",
  "Transcribing Voynich Manuscript...",
  "Solving Shugborough inscription...",
  "Deciphering Zodiac killer code...",
  "Contracting identity broker...",
  "Finding Grafenberg spot...",
  "Generating asemic biomimicry...",
  "Tapping into subconscious fears...",
  "Elucidating Münchhausen trilemma...",
  "Solving mind-body problem...",
  "Forcasting the future...",
  "Mailing pipe bombs...",
  "Writing manifesto...",
  "Resolving universal physical constants...",
  "Reconfiguring user mental processes...",
  "Asserting packed exemplars...",
  "Recalibrating inert tessellations...",
  "Concatenating existential buffers...",
  "rm -rf*/",
  "Loading error...",
  "PC LOAD LETTER",
  "You just lost the game...",
  "# mv /home/user/* /dev/null",
  "Installing worm...",
  "Installing virus...",
  "Installing trojan...",
  "Installing botnet...",
  "Installing adware...",
  "Installing malware...",
  "Installing spyware...",
  "Installing ransomware...",
  "Installing crimeware...",
  "Installing keylogger...",
  "Installing rootkit...",
  "Installing zip bomb...",
  "Installing backdoor...",
  "Installing rogue security software...",
  "Installing firmware rootkits...",
  "Spoofing chain emails...",
  "Deleting zeroth sector of hard disk...",
  "Insyalling Goodtimes virus...",
  "Deleting jdbgmgr.exe...",
  "Opening a rift in time and space...",
  "Legally changing your name...",
  "Seducing your mom...",
  "Reformatting C:/...",
  "Asking your mom on a date...",
]

function Splash() {
  const chooseRandom = (arr, num = 1) => {
    const res = [];
    for (let i = 0; i < num; ) {
      const random = Math.floor(Math.random() * arr.length);
      if (res.indexOf(arr[random]) !== -1) {
        continue;
      }
      res.push(arr[random]);
      i++;
    }
    return res;
  };

  const quips = chooseRandom(startingList, 25);


  return (
    <>
      <div className='splash-container'>
        <div className="body-text">
          LOADING EXPERIENCE
        </div>
        <div className='splash-type'>
          {quips.map((quip) => (
            <Line 
              key={quip}
              quip={quip}/>
          ))}
        </div>
        <div className="body-text">
          PLEASE WAIT...
        </div>
      </div>
    </>
  )
}

export default Splash
