import React, { useRef } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import "./Navbar.css"

const navigation = [
    { title: "home"},
    { title: "videos"},
    { title: "music"},
    { title: "about"},
    { title: "contact"},
    { title: "shop"}
]

function Navbar({ setIsShowing, setCurrent }) {
    const navRef = useRef();
    const showNavbar = () => navRef.current.classList.toggle("responsive_nav")

    const freakMode = () => {
        var element = document.body;
        element.classList.toggle("freak-mode")
      }

  return (
    <div>
        <header>
        <img src="../Images/acid tab trans.png"
             alt="logo"
             className="Logo"
             width="75"
             height="70"
             onClick={freakMode}
             />
            <nav ref={navRef}>
                {navigation.map((navItem) => (
                    <div
                    key={navItem.title}
                    className='nav-links'
                    onClick={ () => {
                        //Set modal to content
                        if (navItem.title === "home") {
                            setIsShowing(false)                     //Hide modal
                        } else {
                        setCurrent(navItem.title)
                        setIsShowing(true)}                         //Show modal
                        showNavbar(false)
                    }}
                    >
                        {navItem.title.toUpperCase()}
                    </div>
                ))}

                <button className='nav-btn nav-close-btn' onClick={showNavbar}>
                    <FaTimes />
                </button>
            </nav>

            <button className='nav-btn' onClick={showNavbar}>
                <FaBars />
            </button>
        </header>
    </div>
  )
}

export default Navbar