import React, { useState, useEffect } from 'react'
import './Home.css'
import PopupModal from '../../Components/Modal/Modal'
import paypal from "./img/paypal.png"
import store from "./img/freakstore.png"
import instagram from "./img/instagram.png"
import youtube from "./img/youtube.png"
import tiktok from "./img/tiktok.png"
import discord from "./img/discord.png"
import patreon from "./img/patreon.png"
import nsf from "./img/nsf.png"
import mookielaka from "./img/mookiegram.png"
import spoogenackin from "./img/spoogenackin.png"
import feffyfussock from "./img/feffyfussock.png"
import theslumcuts from "./img/theslumcuts.png"
import sixex from "./img/6x.png"
import webuyskin from "./img/webuyskin.png"
import fileicon from "./img/fileicon.png"
import mycomputer from "./img/mycomputer.png"
import twitch from "./img/twitch.png"
import PopAttack from '../../Components/Popups/PopAttack'
import Voidman from '../../Components/Voidman/Voidman'
import bookpop from '../../Components/Modal/Img/bookpopup.webp'
import vinylpop from '../../Components/Modal/Img/NOROOMVINYL.webp'


const linkIcons = [
    {title: "My Computer",
    img: mycomputer,
    url: null,
    target: null,
  },
    { title: "FREAKshop",
    img: store,
    url: "https://freakbait.myshopify.com/",
    target: "_blank"},
    { title: "FREAKtreon",
    img: patreon,
    url: "https://www.patreon.com/freakbait",
    target: "_blank"},
    { title: "FREAKtwitch",
    img: twitch,
    url: "https://www.twitch.tv/officialfreakbait/",
    target: "_blank"},
    { title: "FREAKstagram",
    img: instagram,
    url: "https://www.instagram.com/freakbait/",
    target: "_blank"},
    { title: "FREAKtube",
    img: youtube,
    url: "https://www.youtube.com/c/FREAKBAiT",
    target: "_blank"},
     { title: "FREAKTok",
    img: tiktok,
    url: "https://www.tiktok.com/@officialfreakbait",
    target: "_blank"},
    { title: "FREAKpal",
    img: paypal,
    url: "https://www.paypal.com/paypalme/FreakBait",
    target: "_blank"}, 
    // { title: "DisCult",
    // img: discord,
    // url: "https://discord.gg/NHvRsuvJVU",
    // target: "_blank"},
    { title: "The Shortlist",
    img: fileicon,
    url: "https://github.com/FREAKBAiT/THE-SHORTLIST/blob/main/Shortlist.txt",
    target: "_blank"},
  { title: "Mookielaka",
    img: mookielaka,
    url: "https://www.youtube.com/watch?v=5XhA9k7x2TM",
    target: "_blank"},
    { title: "NSF",
    img: nsf,
    url: "https://www.youtube.com/user/NSFHipHop",
    target: "_blank"},
    { title: "Spooge Nackin",
    img: spoogenackin,
    url: "https://spoogenackin.bandcamp.com/album/spooge-nackin",
    target: "_blank"},
    { title: "Feffy Fussock",
    img: feffyfussock,
    url: "https://feffyfussock.bandcamp.com/",
    target: "_blank"},
    { title: "The Slumcuts",
    img: theslumcuts,
    url: "https://theslumcuts.bandcamp.com/",
    target: "_blank"},
    { title: "[6x]",
    img: sixex,
    url: "https://www.youtube.com/user/0011011001111000",
    target: "_blank"},
    { title: "WE BUY SKIN",
    img: webuyskin,
    url: "https://github.com/FREAKBAiT/WEBUYSKIN",
    target: "_blank"},
]

const fakeFiles = [
  "C:/porno/bomb_plans/FREAKBAiT.exe",
  "WARNING: VIRUS VIRUS VIRUS",
  "FREAKBAiT OPERATING SYSTEM",
  "NSA.Backdoor.Win32.Seed",
  "Windows 69",
  "Windows 99",
  "Windows 420",
  "BACKROOMS OS",
  "FREAKBAiT BOTNET",
  "WOPR: War Operation Plan Response",
  "HAL 9000",
  "F.U.C.K.U.P.",
  "Deep Thought 2.0",
  "V.A.L.I.S.",
  "GERTY 3000",
  "Compy 386",
  "THE CENTRAL SCRUTINIZER",
  "[fnord] Computer Program [fnord]",
  "Programm von Unaussprechlichen Kulten",
  "MissingNo. █▒▓■▓░",
  "変質者のコンピューター",
  "Eschaton Immanentizer v420.69",
  "FBI Mainframe",
  "CIA Mainframe",
  "NSA Mainframe",
  "Roko's Basilisk",
  "I.N.T.E.L.L.I.G.E.N.C.E.",
  "McDonald's Corporate HQ",
  "MKULTRA.exe",
]

const pullRandom = (arr, num = 1) => {
  const res = [];
  for (let i = 0; i < num; ) {
    const random = Math.floor(Math.random() * arr.length);
    if (res.indexOf(arr[random]) !== -1) {
      continue;
    }
    res.push(arr[random]);
    i++;
  }
  return res;
};

const fakeTitle = pullRandom(fakeFiles)

function Home() {
  const [popups, setPopups] = useState(false);
//const [isShowing, setIsShowing] = useState(false);//
const [isModalOpen, setIsModalOpen] = useState(true);
useEffect(() => {
  const delay = setTimeout(() => {
    setIsModalOpen(true);
        clearTimeout(delay);
  }, 2000); // Adjust the delay in milliseconds (2000ms = 2 seconds)
}, []);

  
  const clickOut = () => {
    setPopups(!popups)
  }

  return (
<>
    <div className='home-text'>
            Welcome to FREAKBAiT.com, the website that visits you.
    </div>

<div className = 'home-container'>



  <div className='hidden-text'>can you unthink a thought?
  <br/>
  does it leave a ghost behind?
  <br/>
  does the ship of theseus 
  <br/>
  apply to <a href="http://www.greyfieldghostbox.com" target="_blank" rel="noreferrer">your mind?</a></div>


<div className='resizable-window'>
      <div className='home-page'>
      <div className="home-title">{fakeTitle}
        <button className='fake-btn'
          onClick={clickOut}
        >×</button>
      </div>
        
        <ul>
          {linkIcons.map((deskIcons) => (
          <li key={deskIcons.title}>
            <div className='link-list' >
                <a href={deskIcons.url} 
                target={deskIcons.target} 
                alt={deskIcons.title.toUpperCase()}>
                <img src={deskIcons.img} width="45" height="45" alt={deskIcons.title.toUpperCase()} title={deskIcons.title}/><br/>{deskIcons.title}
              </a>
            </div>
          </li>
            ))}
        </ul> 
      </div>
      <PopupModal
            isShowing={isModalOpen}
            setIsShowing={setIsModalOpen}
            title="FREAKBAiT MADE A RECORD"
          >
            <div className="center-content">
              <a href="https://www.diggersfactory.com/vinyl/320100/mookielaka-no-room-left" target="_blank" rel="noopener noreferrer">
                <img src={vinylpop} alt="FREAKBAiT MADE A RECORD" style={{ maxWidth: "100%", height: "auto" }} />
              </a>
              <a href="https://www.diggersfactory.com/vinyl/320100/mookielaka-no-room-left" target="_blank" rel="noopener noreferrer"><b>Featuring four different versions of No Room Left: <br/> the original, the instrumental, the AI edition, and the overdose edition.</b> <br/>
              Pressed on colored vinyl to match the cover art. <br/> Gatefold sleeve, individually numbered, 140g virgin vinyl at 45prm. <br/>
              <b><large>AVAILABLE UNTIL SEPTEMBER 16TH 2024</large></b></a>
            </div>
          </PopupModal>
  {/* <PopupModal
        isShowing={isModalOpen}
        setIsShowing={setIsModalOpen}
        title="FREAKBAiT WROTE A BOOK"
      >
        <p>
        <a href="https://freakbait.myshopify.com/products/freakbait-the-book" target="_blank" rel="noopener noreferrer">
                  <img src={bookpop} alt="FREAKBAiT WROTE A BOOK" style={{ maxWidth: "100%", height: "auto" }}  /> </a>
                  <br/><br/>
                  All copies purchased at the <a href="https://freakbait.myshopify.com/products/freakbait-the-book" target="_blank" rel="noopener noreferrer">FREAKBAiT store</a> include a <b>surprise sticker & extras</b> added with the order. Signed upon request (for the same price).</p> 
                  <br/><p style={{ fontSize: "x-small" }}>You can also get it on <a href="https://www.amazon.com/FREAKBAiT-Book/dp/B0CLK6FXJX/" target="_blank" rel="noopener noreferrer">Amazon</a> if you wanna give daddy Bezos money instead (but no free sticker, extras, or signature).
        </p>
      </PopupModal>  */}


      <div className='footer'>
  © designed and coded by FREAKBAiT 2024
</div>
</div>

<div>
        <Voidman />
</div>

  <div className='popups-container'>
  {popups ? 
  <>
    <PopAttack />
  </>
    : null }
  </div>
  </div>
  </>
  )
}
export default Home
