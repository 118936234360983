import React, { useState, useEffect } from "react";
import Navbar from "./Navbar/Navbar" ;
import Alert from "./Alert/Alert";
import Splash from "./Splash";
import Modal from "./Modal/Modal";
import ReactGa from 'react-ga';

//pages//
import Home from "../Pages/Home/Home";
import About from "../Pages/About/About";
import Contact from "../Pages/Contact/Contact";
import Music from "../Pages/Music/Music";
import Videos from "../Pages/Videos/Videos";
import Shop from "../Pages/Shop/Shop";

 
//analytics
  ReactGa.initialize('UA-242062058-1', [])
//pageviews
  ReactGa.pageview('/app')
  ReactGa.pageview('/overlay')

function App() {
  const [popup, setPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [current, setCurrent] = useState("")

  function modalContent() {
    if (current === "about") return <About />
    if (current === "contact") return <Contact />
    if (current === "music") return <Music />
    if (current === "videos") return <Videos />
    if (current === "shop") return <Shop />
  }

  useEffect(() => {
    setTimeout (() => {
      setPopup(true)
    }, 3000);
  }, []);

  useEffect(() => {
    setLoading(true)
    setTimeout(()=> {
      setLoading(false)
    }, 2000)
  }, []);

  return (
    <div className="App">
      <Navbar setIsShowing={setIsShowing} setCurrent={setCurrent}/>
      {loading ? <Splash /> : <Home />}
      <Modal
        isShowing={isShowing}
        setIsShowing={setIsShowing}
        title={current}>
          {modalContent()}
        </Modal>
      <Alert open={popup} setPopup={setPopup} />
    </div>
  );
}

export default App;
