import React from 'react'
import "./Alert.css"

function Alert(props) {
  return (props.open) ? (
    <div className='alert-container'>
        <div className='alert'>
        This website has been developed and implemented in conjunction with the Central Intelligence Agency, National Security Agency, Federal Bureau of Investigation, Drug Enforcement Administration, National Reconnaissance Office, Department of Defense, Bureau of Alcohol Tobacco Firearms & Explosives, and McDonald's Corporation. By clicking ACCEPT below, you agree to being subject to interminable surveillance, behavioral modification, remote dream transmissions, coercive persuasion, and undisclosed psychological experimentation.            
          <button 
                className='close-btn' 
                onClick={() => props.setPopup(false)}>
                    ACCEPT
          </button>
        </div>
    </div>
  ) : "";
} //

export default Alert;